<div mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon  class="close-icon" color="warn">close</mat-icon>
</div>

<!-- <mat-dialog-content class="pb-3"> -->
  <div fxLayout="row wrap">
  <div fxFlex="100" fxFlex.gt-md="100" fxFlex.gt-xs="100">
    <h4 class="m-l-10 m-r-10 popuplineheight" align="center">
      {{data.message}}
    </h4>

    <h4 class="m-l-10 m-r-10 popuplineheight font-22" align="center" style="margin-top: -5px!important;">
      {{data.message1}}
    </h4>
  </div>
</div>
<!-- </mat-dialog-content> -->

<div mat-dialog-actions align="center" class="m-b-0 btmspacing1">
  <button *ngIf="!data.bankAcc && !data.googleAcc" class="bg-danger text-white" mat-button (click)="onNoClick()" tabindex="-1">Close</button>
  <button *ngIf="data.bankAcc" class="bg-danger text-white" mat-button [routerLink]="['/bankdetails']" (click)="onNoClick()" tabindex="-1">Take me to Bank details</button>
 
  <mat-card-actions class="btmspacing2" *ngIf="data.googleAcc">
      <a class="m-r-10" routerLink='/gam_invite'>
          <button mat-stroked-button color="" 
              class="m-b-10 m-r-10 bg-danger text-white" (click)="onNoClick()">Add more domains</button>
      </a>

      <a class="m-r-10" routerLink='/bankdetails'>
          <button mat-stroked-button color=""
              class="m-b-10 m-r-10 bg-danger text-white" (click)="onNoClick()">Update bank details</button>
      </a>

      <a class="m-r-10" routerLink='/logout'>
          <button mat-stroked-button color="" class="m-b-10 m-r-10 bg-danger text-white" (click)="onNoClick()">Logout</button>
      </a>
  </mat-card-actions>
</div>
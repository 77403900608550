import {
  ChangeDetectorRef,
  Component, OnDestroy
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { AccHorizontalMenuItems } from '../../shared/menu-items/acc_mgr-horizontal-menu-items';

@Component({
  selector: 'app-acc_mgr-horizontal-sidebar',
  templateUrl: './acc_mgr-horizontal-sidebar.component.html',
  styleUrls: []
})

export class AccManagerHorizontalSidebarComponent implements OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: AccHorizontalMenuItems
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 1024px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}

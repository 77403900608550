import { InvoiceList } from './invoice';

export const invoceLists: InvoiceList[] = [
    {
        id: 101,
        billFrom: 'February 2021 12',
        billTo: '$ 20.00',
        billFromEmail: 'first@xabz.com',
        billFromAddress: 'Ganesh glory,Godrej garden city,Ahmedabad.',
        billFromPhone: 979796786,
        billFromFax: 13,
        billToEmail: "toFirst@agth.com",
        billToAddress: 'Godrej garden city,Ahmedabad.',
        billToPhone: 757575233,
        billToFax: 76,
        orders: [{
            itemName: 'Courge',
            unitPrice: 10,
            units: 9,
            unitTotalPrice: 90
        },
        ],
        orderDate: new Date(),
        status: 'Payment Under Process',
        totalCost: 90,
        vat: 9,
        grandTotal: 99,
        completed: false,
        isSelected: false
    },
    
    {
        id: 102,
        billFrom: 'January 2021',
        billTo: '$ 3213.99',
        billFromEmail: 'first@xabz.com',
        billFromAddress: 'Ganesh glory,Godrej garden city,Ahmedabad.',
        billFromPhone: 979796786,
        billFromFax: 13,
        billToEmail: "toFirst@agth.com",
        billToAddress: 'Godrej garden city,Ahmedabad.',
        billToPhone: 757575233,
        billToFax: 76,
        orders: [{
            itemName: 'Courge',
            unitPrice: 10,
            units: 9,
            unitTotalPrice: 90
        },
        ],
        orderDate: new Date(),
        status: 'Payment Under Process',
        totalCost: 90,
        vat: 9,
        grandTotal: 99,
        completed: false,
        isSelected: false
    },

    {
        id: 103,
        billFrom: 'December 2020',
        billTo: '$ 7706.76',
        billFromEmail: 'first@xabz.com',
        billFromAddress: 'Ganesh glory,Godrej garden city,Ahmedabad.',
        billFromPhone: 979796786,
        billFromFax: 13,
        billToEmail: "toFirst@agth.com",
        billToAddress: 'Godrej garden city,Ahmedabad.',
        billToPhone: 757575233,
        billToFax: 76,
        orders: [{
            itemName: 'Courge',
            unitPrice: 10,
            units: 9,
            unitTotalPrice: 90
        },
        ],
        orderDate: new Date(),
        status: 'Paid',
        totalCost: 90,
        vat: 9,
        grandTotal: 99,
        completed: false,
        isSelected: false
    },

    {
        id: 104,
        billFrom: 'November 2020',
        billTo: '$ 7780.77',
        billFromEmail: 'first@xabz.com',
        billFromAddress: 'Ganesh glory,Godrej garden city,Ahmedabad.',
        billFromPhone: 979796786,
        billFromFax: 13,
        billToEmail: "toFirst@agth.com",
        billToAddress: 'Godrej garden city,Ahmedabad.',
        billToPhone: 757575233,
        billToFax: 76,
        orders: [{
            itemName: 'Courge',
            unitPrice: 10,
            units: 9,
            unitTotalPrice: 90
        },
        ],
        orderDate: new Date(),
        status: 'Approval Pending',
        totalCost: 90,
        vat: 9,
        grandTotal: 99,
        completed: false,
        isSelected: false
    },

    {
        id: 105,
        billFrom: 'October 2020',
        billTo: '$ 5752.37',
        billFromEmail: 'first@xabz.com',
        billFromAddress: 'Ganesh glory,Godrej garden city,Ahmedabad.',
        billFromPhone: 979796786,
        billFromFax: 13,
        billToEmail: "toFirst@agth.com",
        billToAddress: 'Godrej garden city,Ahmedabad.',
        billToPhone: 757575233,
        billToFax: 76,
        orders: [{
            itemName: 'Courge',
            unitPrice: 10,
            units: 9,
            unitTotalPrice: 90
        },
        ],
        orderDate: new Date(),
        status: 'Paid',
        totalCost: 90,
        vat: 9,
        grandTotal: 99,
        completed: false,
        isSelected: false
    },

    {
        id: 106,
        billFrom: 'September 2020',
        billTo: '$ 3367.86',
        billFromEmail: 'first@xabz.com',
        billFromAddress: 'Ganesh glory,Godrej garden city,Ahmedabad.',
        billFromPhone: 979796786,
        billFromFax: 13,
        billToEmail: "toFirst@agth.com",
        billToAddress: 'Godrej garden city,Ahmedabad.',
        billToPhone: 757575233,
        billToFax: 76,
        orders: [{
            itemName: 'Courge',
            unitPrice: 10,
            units: 9,
            unitTotalPrice: 90
        },
        ],
        orderDate: new Date(),
        status: 'Paid',
        totalCost: 90,
        vat: 9,
        grandTotal: 99,
        completed: false,
        isSelected: false
    },

    {
        id: 107,
        billFrom: 'August 2020',
        billTo: '$ 5262.49',
        billFromEmail: 'first@xabz.com',
        billFromAddress: 'Ganesh glory,Godrej garden city,Ahmedabad.',
        billFromPhone: 979796786,
        billFromFax: 13,
        billToEmail: "toFirst@agth.com",
        billToAddress: 'Godrej garden city,Ahmedabad.',
        billToPhone: 757575233,
        billToFax: 76,
        orders: [{
            itemName: 'Courge',
            unitPrice: 10,
            units: 9,
            unitTotalPrice: 90
        },
        ],
        orderDate: new Date(),
        status: 'Paid',
        totalCost: 90,
        vat: 9,
        grandTotal: 99,
        completed: false,
        isSelected: false
    },

    {
        id: 108,
        billFrom: 'July 2020',
        billTo: '$ 8608.45',
        billFromEmail: 'first@xabz.com',
        billFromAddress: 'Ganesh glory,Godrej garden city,Ahmedabad.',
        billFromPhone: 979796786,
        billFromFax: 13,
        billToEmail: "toFirst@agth.com",
        billToAddress: 'Godrej garden city,Ahmedabad.',
        billToPhone: 757575233,
        billToFax: 76,
        orders: [{
            itemName: 'Courge',
            unitPrice: 10,
            units: 9,
            unitTotalPrice: 90
        },
        ],
        orderDate: new Date(),
        status: 'Paid',
        totalCost: 90,
        vat: 9,
        grandTotal: 99,
        completed: false,
        isSelected: false
    },

    {
        id: 109,
        billFrom: 'June 2020',
        billTo: '$ 896.21',
        billFromEmail: 'first@xabz.com',
        billFromAddress: 'Ganesh glory,Godrej garden city,Ahmedabad.',
        billFromPhone: 979796786,
        billFromFax: 13,
        billToEmail: "toFirst@agth.com",
        billToAddress: 'Godrej garden city,Ahmedabad.',
        billToPhone: 757575233,
        billToFax: 76,
        orders: [{
            itemName: 'Courge',
            unitPrice: 10,
            units: 9,
            unitTotalPrice: 90
        },
        ],
        orderDate: new Date(),
        status: 'Paid',
        totalCost: 90,
        vat: 9,
        grandTotal: 99,
        completed: false,
        isSelected: false
    },

    {
        id: 110,
        billFrom: 'May 2020',
        billTo: '$ 9.15',
        billFromEmail: 'first@xabz.com',
        billFromAddress: 'Ganesh glory,Godrej garden city,Ahmedabad.',
        billFromPhone: 979796786,
        billFromFax: 13,
        billToEmail: "toFirst@agth.com",
        billToAddress: 'Godrej garden city,Ahmedabad.',
        billToPhone: 757575233,
        billToFax: 76,
        orders: [{
            itemName: 'Courge',
            unitPrice: 10,
            units: 9,
            unitTotalPrice: 90
        },
        ],
        orderDate: new Date(),
        status: 'Paid',
        totalCost: 90,
        vat: 9,
        grandTotal: 99,
        completed: false,
        isSelected: false
    },

    {
        id: 111,
        billFrom: 'April 2020',
        billTo: '$ 19.61',
        billFromEmail: 'first@xabz.com',
        billFromAddress: 'Ganesh glory,Godrej garden city,Ahmedabad.',
        billFromPhone: 979796786,
        billFromFax: 13,
        billToEmail: "toFirst@agth.com",
        billToAddress: 'Godrej garden city,Ahmedabad.',
        billToPhone: 757575233,
        billToFax: 76,
        orders: [{
            itemName: 'Courge',
            unitPrice: 10,
            units: 9,
            unitTotalPrice: 90
        },
        ],
        orderDate: new Date(),
        status: 'Paid',
        totalCost: 90,
        vat: 9,
        grandTotal: 99,
        completed: false,
        isSelected: false
    }
];
<div mat-icon-button class="close-button m-t-5" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</div>
<h2 class="font-bold m-l-10" mat-dialog-title><strong>Quick Tags</strong></h2>
<form class="m-t-0  p-b-0">
  <mat-dialog-content class="pb-3">
    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="100" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0 m-b-f5">
          <mat-label>Select Domain</mat-label>
          <mat-select matInput required #fontSize value="16px">
            <mat-option value="">zemtv.com</mat-option>
            <mat-option value="">picbon.com</mat-option>
            <mat-option value="">Picluck.net</mat-option>
            <mat-option value="">picbon.com</mat-option>
            <mat-option value="">Picluck.net</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="40" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0">
          <input matInput id="" name="pubid" placeholder="CM_webneel.com_entertainment_TOP_responsive_WC1" value="">
        </mat-form-field>
      </div>
      <div  fxFlex.gt-sm="10" fxFlex="100" class="p-10 p-b-30 respombilebnk" style="margin-top: 9px;">
        <div mat-card-avatar style="height: 45px;  width: 45px;">
          <img  src="assets/images/users/copy.png" class="img-fluid" />
        </div> 
      </div>

      <div fxFlex.gt-sm="40" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0">
          <input matInput id="" name="pubid" placeholder="CM_webneel.com_entertainment_ATF_responsive_WC2" value="">
        </mat-form-field>
      </div>
      <div  fxFlex.gt-sm="10" fxFlex="100" class="p-10 p-b-30 respombilebnk" style="margin-top: 9px;">
        <div mat-card-avatar style="height: 45px;  width: 45px;">
          <img  src="assets/images/users/copy.png" class="img-fluid" />
        </div> 
      </div>

      <div fxFlex.gt-sm="40" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0">
          <input matInput id="" name="pubid" placeholder="CM_webneel.com_entertainment_BTF_responsive_WC3" value="">
        </mat-form-field>
      </div>
      <div  fxFlex.gt-sm="10" fxFlex="100" class="p-10 p-b-30 respombilebnk" style="margin-top: 9px;">
        <div mat-card-avatar style="height: 45px;  width: 45px;">
          <img  src="assets/images/users/copy.png" class="img-fluid" />
        </div> 
      </div>

      <div fxFlex.gt-sm="40" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0">
          <input matInput id="" name="pubid" placeholder="CM_webneel.com_entertainment_EOP_responsive_WC4" value="">
        </mat-form-field>
      </div>
      <div  fxFlex.gt-sm="10" fxFlex="100" class="p-10 p-b-30 respombilebnk" style="margin-top: 9px;">
        <div mat-card-avatar style="height: 45px;  width: 45px;">
          <img  src="assets/images/users/copy.png" class="img-fluid" />
        </div> 
      </div>

      <div fxFlex.gt-sm="90" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0">
          <input matInput id="" name="pubid" placeholder="CM_webneel.com_entertainment_EOP_responsive_WC4" value="">
        </mat-form-field>
      </div>
      <div  fxFlex.gt-sm="10" fxFlex="100" class="p-10 p-b-30 respombilebnk" style="margin-top: 9px;">
        <div mat-card-avatar style="height: 45px;  width: 45px;">
          <img  src="assets/images/users/copy.png" class="img-fluid" />
        </div> 
      </div>
    </div>
  </mat-dialog-content>
</form>
<!-- <div mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</div>

<div fxLayout="row wrap">
  <div fxFlex="100" fxFlex.gt-md="100" fxFlex.gt-xs="100">
    <h4 class="m-l-10 m-r-10 popuplineheight" align="center">
      Update eCPM Value For All Order
    </h4>
  </div>
</div>

<div mat-dialog-actions align="center" class="m-b-0 btmspacing1">
  <mat-card-actions class="btmspacing2">
    <form>
      <div fxLayout="row wrap" fxFlexAlign="center" class="row">
        <div fxFlex.gt-sm="100" fxFlex="100" class="p-10">
          <mat-form-field appearance="outline" class="p-r-0">
            <mat-label>eCPM Value</mat-label>
            <input matInput id="" name="" placeholder="Enter eCPM Value">
          </mat-form-field>
        </div>

        <div fxFlex.gt-sm="100" fxFlex="100" class="p-10">
          <div class="p-r-0 text-center">
            <button mat-stroked-button color="" id="" class=" bg-danger text-white">Submit</button>
          </div>
        </div>
      </div>
    </form>
  </mat-card-actions>
</div> -->

<div mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</div>

<div fxLayout="row wrap">
  <div fxFlex="100" fxFlex.gt-md="100" fxFlex.gt-xs="100">
    <h4 class="m-l-10 m-r-10 popuplineheight" align="center">
      Update eCPM Value For All Order
    </h4>
  </div>
</div>

<form [formGroup]="ecpmallForm" (ngSubmit)="updateallCPM()">
  <div fxLayout="row wrap" fxFlexAlign="center">
    <div fxFlex.gt-sm="100" fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" class="p-10">
      <mat-form-field appearance="outline" class="p-r-0">
        <mat-label>Set eCPM Value</mat-label>
        <input matInput [formControl]="ecpmallForm.controls['set_all_ecpm']" placeholder="Enter eCPM Value">
      </mat-form-field>
      <small
        *ngIf="ecpmallForm.controls['set_all_ecpm'].errors?.whitespace && ecpmallForm.controls['set_all_ecpm'].touched"
        class="text-danger support-text logintrp-css spacing-adjust">Please enter valid eCPM value</small>
      <small *ngIf="ecpmallForm.controls['set_all_ecpm'].errors?.pattern"
        class="text-danger support-text logintrp-css spacing-adjust">eCPM value must be numeric characters</small>
    </div>

    <div fxFlex.gt-sm="100" fxFlex="100" class="p-10">
      <div class="p-r-0 text-center">
        <button mat-stroked-button class=" bg-danger text-white">Submit</button>
      </div>
    </div>
  </div>
</form>
<div class="bg-opacity" *ngIf="isSpinnerVisible">
  <div class="parent">
    <div class="spin1"></div>
  </div>
</div>
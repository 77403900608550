<div fxLayout="row wrap">
    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap">
                    <div fxFlex.gt-lg="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-card-title class="m-l-0 m-t-10">Manage Ads.Txt</mat-card-title>
                    </div>

                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-card-title style="cursor: pointer;" class="text-right">
                            <button mat-stroked-button (click)="addDialog()" color="" id=""
                                class=" bg-danger text-white">Add</button>
                        </mat-card-title>
                    </div>
                </div>
                
                <div class="p-5 m-t-15 p-r-20 p-l-20" style="background-color: aliceblue;">
                    <mat-form-field>
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                    </mat-form-field>
                </div>

                <div class="table-responsive">
                    <table mat-table [dataSource]="dataSource" matSort class="no-wrap m-t-30 v-middle"
                        style="width: 100%;">
                        <ng-container matColumnDef="network_domain">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Ad Network Domain </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal"> {{row.network_domain}} </td>
                        </ng-container>

                        <ng-container matColumnDef="network_acc_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Ad Network Account Id </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal text-right"> {{row.network_acc_id}} </td>
                        </ng-container>

                        <ng-container matColumnDef="acc_type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Account Type</th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal text-right"> {{row.acc_type}} </td>
                        </ng-container>

                        <ng-container matColumnDef="authority_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Certification Authority ID (optional)</th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal text-right"> {{row.authority_id}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                </div>
            </mat-card-content>
            <div class="bg-opacity" *ngIf="isSpinnerVisible">
                <div class="parent">
                    <div class="spin1"></div>
                </div>
            </div>
        </mat-card>
    </div>
</div>
<div fxLayout="row wrap">
    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <mat-card-title>User Information : </mat-card-title>
                <!-- <div class="p-5 p-r-20 p-l-20" style="background-color: aliceblue;">
                    <mat-form-field>
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                    </mat-form-field>
                </div> -->

                <form class="m-t-10 p-b-0" [formGroup]="filterform">
                    <div fxLayout="row wrap" fxFlexAlign="center" class="row">
                        <div fxFlex.gt-sm="50" fxFlex.gt-lg="23" fxFlex.gt-md="23" fxFlex.gt-xs="100" fxFlex="100"
                            class="p-10">
                            <mat-form-field appearance="outline" class="p-r-0 m-b-f5">
                                <mat-label>User Role</mat-label>
                                <mat-select matInput #fontSize value="16px" (selectionChange)="customFilter()" [formControl]="filterform.controls['user_role']">
                                    <mat-option value="7">Sales Manager</mat-option>
                                    <mat-option value="8">Sales Executive</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex.gt-sm="50" fxFlex.gt-lg="23" fxFlex.gt-md="23" fxFlex.gt-xs="100" fxFlex="100"
                            class="p-10">
                            <mat-form-field appearance="outline" class="p-r-0 m-b-f5">
                                <mat-label>User Status</mat-label>
                                <mat-select matInput #fontSize value="16px" (selectionChange)="customFilter()" [formControl]="filterform.controls['user_status']">
                                    <mat-option value="Y">Active</mat-option>
                                    <mat-option value="N">InActive</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex.gt-sm="100" fxFlex.gt-lg="8" fxFlex.gt-md="8" fxFlex.gt-xs="100" fxFlex="100"
                                    class="p-10 m-t-15 text-right ResetGoBtnMobile">
                            <button mat-stroked-button color="" class="bg-danger text-white" (click)="resetFormfilter()">Reset</button>
                        </div>
                    </div>
                </form>

                <div class="table-responsive">
                    <table mat-table [dataSource]="roleUserList" matSort class="no-wraap m-t-0 v-middle"
                        style="width: 100%;">

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                User Name </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal">{{row.userName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="progress">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                User Email Id </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal"> {{row.emailId}} </td>
                        </ng-container>

                        <ng-container matColumnDef="color">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                User Role </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal"> {{row.userRole}} </td>
                        </ng-container>

                        <ng-container matColumnDef="services">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                User On Board Date </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal"> {{row.onboardedDate}} </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                User Status </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal" [style.color]="row.color">
                                <p *ngIf="row.status=='Y'" class="text-success">Active</p>
                                <p *ngIf="row.status=='N'" class="text-danger">InActive</p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="board">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-20">
                                Action </th>
                            <td mat-cell *matCellDef="let element" class="p-20 font-normal text-center action-link">
                                <a (click)="editDialog(element.id,element.emailId)" matTooltip="View / Update User Details"
                                    aria-label="tooltip" class="m-r-20 cursor-pointer">
                                    <i class="fa fa-pencil font-20 text-danger networkpartner-mobile"></i>
                                </a>

                                <a [routerLink]="['../sales/central-detailed-dashboard',element.sales_id]"
                                    matTooltip="View User Person Detail" aria-label="tooltip"
                                    class="m-r-20 cursor-pointer">
                                    <i class="fa fa-info font-20 text-danger networkpartner-mobile"></i>
                                </a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[25, 100]"></mat-paginator>
                </div>
            </mat-card-content>
            <div class="bg-opacity" *ngIf="isSpinnerVisible">
                <div class="parent">
                    <div class="spin1"></div>
                </div>
            </div>
        </mat-card>
    </div>
</div>
import { Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { InvoiceService } from './invoice.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, NgForm } from '@angular/forms';
import moment from 'moment';
//import { ActivityData, ActivityDataItem, EditActivityData } from './activity-data';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Tracing } from 'trace_events';
import { FinanceDialogDynamicComponent } from '../finance-dialog-dynamic/finance-dialog-dynamic.component';
import { UserData } from './invoice.component';

declare var $: any;

// Popup start
@Component({
    selector: 'app-dialog-invoice-disapprove',
    templateUrl: 'dialog-invoice-disapprove.html'
})

export class DialogInvoiceDisapproveComponent {
  public accholdername:any;
  public pub_uniq_id:any;
  public invoice_id:any;
  public month:any;
  public message:any;
  isSpinnerVisible: boolean = false;
  status: any;
  finance_invoice_id: any;
  constructor(
    public dialogRef: MatDialogRef<DialogInvoiceDisapproveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,private fb: FormBuilder,private _invoiceService: InvoiceService
  ) {
    this.pub_uniq_id = data.pubUniqId;
    this.invoice_id = data.invoiceId;
    this.finance_invoice_id=data.financeInvoiceId;
    this.month = data.month;
    this.status=data.status;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  disapproveSubmit(){
    if(this.pub_uniq_id!=null && this.finance_invoice_id!=null && this.status!=null){
      this.isSpinnerVisible = true;
      this._invoiceService.dispproveInvoice(this.pub_uniq_id,this.finance_invoice_id,this.status).subscribe(dataResult => {
        if (dataResult['status_code'] == '200') {
          this.onNoClick();
          this.message = "Status disapproved successfully.";
          this.dialog.open(FinanceDialogDynamicComponent,
              {
                  width: '450px',
                  data: { message: this.message }
              });
          this.isSpinnerVisible = false;
        } else {
          this.onNoClick();
          this.message = "Status not disapproved.";
          this.dialog.open(FinanceDialogDynamicComponent,
              {
                  width: '450px',
                  data: { message: this.message }
              });
          this.isSpinnerVisible = false;
        }
      });
    }else{
      
    }
    
  }
  
}
// popup end





<div mat-icon-button class="close-button m-t-5" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</div>
<h2 class="font-bold m-l-10" mat-dialog-title><strong>Update</strong></h2>
<form class="m-t-0  p-b-0">
  <mat-dialog-content class="pb-3">
    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>BCR Month Year (February 2022)</mat-label>
          <input matInput id="" name="" placeholder="Enter BCR Month Year (February 2022)">
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>BCR Amount</mat-label>
          <input matInput id="" name="" placeholder="Enter BCR Amount">
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>Content Earning Adjustment</mat-label>
          <input matInput id="" name="" placeholder="Enter Content Earning Adjustment">
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>Video Earning Adjustment</mat-label>
          <input matInput id="" name="" placeholder="Enter Video Earning Adjustment">
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>Network Partner Adjustment</mat-label>
          <input matInput id="" name="" placeholder="Enter Network Partner Adjustment">
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>Minimum Revenue for Adjustment</mat-label>
          <input matInput id="" name="" placeholder="Enter Minimum Revenue for Adjustment">
        </mat-form-field>
      </div>      

      <div fxFlex.gt-sm="100" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>Total Network Partner for Adjustment</mat-label>
          <input matInput id="" name="" placeholder="Enter Total Network Partner for Adjustment">
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="100" fxFlex="100" class="p-10">
        <div class="p-r-0 m-b-10 m-t-5 text-center">
          <button mat-stroked-button color="" id="" class=" bg-danger text-white">Submit</button>
        </div>
      </div>

    
    </div>
  </mat-dialog-content>
</form>
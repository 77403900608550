<div mat-icon-button class="close-button m-t-5" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </div>
  <h2 class="font-bold m-l-10" mat-dialog-title><strong>Add Users</strong></h2>
  <form class="m-t-0  p-b-0" [formGroup]="addUserForm" (ngSubmit)="addUserFormSubmit()">
    <mat-dialog-content class="pb-3">
      <div fxLayout="row wrap">
        <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
          <mat-form-field appearance="outline" class="p-r-0">
            <mat-label>First Name</mat-label>
            <input matInput [formControl]="addUserForm.controls['fname']" placeholder="Enter First Name">
          </mat-form-field>
          <small *ngIf="addUserForm.controls['fname'].errors?.whitespace && addUserForm.controls['fname'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Please enter valid first name</small>
          <small *ngIf="addUserForm.controls['fname'].errors?.minlength"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth"> First name must be 3 characters</small>
          <small *ngIf="addUserForm.controls['fname'].errors?.pattern"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">First name must be characters</small>
        </div>

        <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
          <mat-form-field appearance="outline" class="p-r-0">
            <mat-label>Last Name</mat-label>
            <input matInput [formControl]="addUserForm.controls['lname']" placeholder="Enter Last Name">
          </mat-form-field>
          <small *ngIf="addUserForm.controls['lname'].errors?.pattern"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Last name must be characters</small>
        </div>

        <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
          <mat-form-field appearance="outline" class="p-r-0">
            <mat-label>Email</mat-label>
            <input matInput [formControl]="addUserForm.controls['email']" placeholder="Enter Email">
          </mat-form-field>
          <small *ngIf="addUserForm.controls['email'].errors && addUserForm.controls['email'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Must be a valid email address</small>
          <small *ngIf="emailCheck" class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">E-Mail address already exists, please check details!</small> 
        </div>

        <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
          <mat-form-field appearance="outline" class="p-r-0">
            <mat-label>Login Password</mat-label>
            <input matInput [formControl]="addUserForm.controls['password']" placeholder="Enter Login Password">
          </mat-form-field>
          <small *ngIf="addUserForm.controls['password'].errors?.minlength && addUserForm.controls['password'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Password must be at least 8 characters</small>
        </div>

        <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
          <mat-form-field appearance="outline" class="p-r-0">
            <mat-label>Contact Number</mat-label>
            <input matInput [formControl]="addUserForm.controls['contact']" placeholder="Enter Contact Number">
          </mat-form-field>
          <small *ngIf="addUserForm.controls['contact'].errors?.pattern"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Please enter valid contact number</small>
          <small *ngIf="addUserForm.controls['contact'].errors?.minlength && addUserForm.controls['contact'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth"> Contact number must be 10 numeric characters</small>
        </div>

        <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
          <mat-form-field appearance="outline" class="p-r-0 m-b-f5">
            <mat-label>Select User Role</mat-label>
            <mat-select [formControl]="addUserForm.controls['roleId']" matInput #fontSize value="16px">
              <mat-option *ngFor="let rolelist of rolelist;" value="{{rolelist.role_id}}">
                {{rolelist.role_name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex.gt-sm="100" fxFlex="100" class="p-10">
          <div class="p-r-0 m-b-10 m-t-5 text-center">
            <button mat-stroked-button color="" id="" class=" bg-danger text-white">Submit</button>
          </div>
        </div>
  
      
      </div>
    </mat-dialog-content>
    <div class="bg-opacity" *ngIf="isSpinnerVisible">
      <div class="parent">
        <div class="spin1"></div>
      </div>
    </div>
  </form>
<div mat-icon-button class="close-button m-t-5" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</div>
<h2 class="font-bold m-l-10" mat-dialog-title><strong>SMS Verification</strong></h2>

<form class="m-t-0  p-b-0" [formGroup]="myForm" (ngSubmit)="onSubmit()" novalidate  >
  <mat-dialog-content>
    <div fxLayout="row wrap" style="margin-top: -10px!important">
      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">   
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>Mobile No.*</mat-label>
          <input type="text" formControlName="mob" matInput placeholder="Mobile No." [(ngModel)]="contact" >
      </mat-form-field>

      <div *ngIf="m.mob.touched && m.mob.invalid" class="alert alert-danger">
          <div *ngIf="m.mob.errors?.required" class="alert alert-danger text-danger">Please enter mobile number</div>
          <div *ngIf="m.mob.errors?.pattern" class="alert alert-danger text-danger">Please enter valid mobile number</div>
        </div>
      </div>

      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">   
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>OTP*</mat-label>
          <input  type="text" matInput placeholder="Enter OTP"  formControlName="otp"  >
        </mat-form-field>
      </div>
          
      <div fxFlex.gt-sm="100" fxFlex="100" class="p-10">
        <div class="p-r-0 m-b-10 m-t-5 text-center">
          <button *ngIf="!isOtp" mat-stroked-button color="" id="" class=" bg-danger text-white" >Send</button>
          <button *ngIf="isOtp" mat-stroked-button color="" id="" class=" bg-danger text-white" >Verify</button>
          <a (click)="resendOtp()" [disabled]="!isOtp" mat-stroked-button color="" id="" class=" bg-danger text-white m-l-10" >Resend</a>
        </div>
      </div>
    </div>
  </mat-dialog-content> 
  <!---Loader Start-->
    <div class="bg-opacity" *ngIf="isSpinnerVisible">
      <div class="parent">
        <div class="spin1"></div>
      </div>
    </div>
    <!---Loader End-->
</form>


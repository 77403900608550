import { Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { InvoiceUploadService } from './invoice-upload.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Tracing } from 'trace_events';
import { EditActivityData } from 'src/app/admin/admin-activity-control/admin-activity-data';
import { FinanceDialogDynamicComponent } from '../finance-dialog-dynamic/finance-dialog-dynamic.component';
import { UserData } from './invoice-upload.component';

declare var $: any;
const MaxFileSize =2048000;
// Popup start
@Component({
    selector: 'app-dialog-invoice-upload',
    templateUrl: 'dialog-invoice-upload.html'
})

export class DialogInvoiceUploadComponent  {
    isSpinnerVisible: boolean;
    fileList:FileList;
    hasFileValidationError =false;
    fileErrorMessage ='';
    login_uniq_id: string;
    pub_uniq_id: string;
    invoice_id:string;
    invoice_month:string;
    message:any;
    selectedValue : string;
    activity_file:string;
    alreadyExistData:string = "No";
    child_net_code:string;
    isBusy=false;
    isEdit=false;
    isChanged=false;
    type:string;
    status: any;
    operation_type:string;
   finance_invoice_id: any;
    upload: any;
    isSubmitted=false;
    constructor(
        public dialogRef: MatDialogRef<DialogInvoiceUploadComponent>,
        @Inject(MAT_DIALOG_DATA) public model: any,public _invoiceUploadService:InvoiceUploadService,private router: Router,public dialog: MatDialog
    ) { 

          this.pub_uniq_id=model.pubUniqId;     
          this.child_net_code=model.childNetCode;     
          this.invoice_id=model.invoiceId; 
          this.invoice_month=model.month;
          this.status=model.status;
          this.login_uniq_id=localStorage.getItem('uniq_id');
          if(this.status == '' || this.status==null){
            this.operation_type = 'insert';
          }else if(this.status == 2){
            this.operation_type = 'update';
            this.finance_invoice_id=model.finance_invoice_id;
            this.upload=model.upload;
          }
    }
  formChangeSubscription:Subscription;
  ngOnInit(): void {
   this.isSubmitted=false;
  }

    onNoClick(): void {
        this.dialogRef.close(false);
    }
    onFormChange(){
      this.isChanged=true;
    }
   
    onSubmit(f:NgForm){  

      if(this.hasFileValidationError || !this.fileList || this.fileList.length == 0){
        this.hasFileValidationError=true;
        this.fileErrorMessage='Please select a file';
        return;
      }
      this.isSpinnerVisible = true;    
      this._invoiceUploadService  
         .uploadFiles(this.pub_uniq_id,this.child_net_code,this.invoice_id,this.invoice_month,this.login_uniq_id,this.fileList[0],this.operation_type,this.finance_invoice_id,this.upload)
         .subscribe((response:any)=>{  
            this.isSpinnerVisible=false;
            let resp = this.parseResponse(response);
            if(!resp.success){
              this.showDialog(resp.message);
              return;
            }
            if(!this.fileList || this.fileList.length == 0){
              this.showDialog(resp.message);
              this.isSubmitted=true;
              this.dialogRef.close(true);
              return;
            }
            this.dialog.open(FinanceDialogDynamicComponent,
              {
                  width: '450px',
                  data: { message: 'File uploaded successfully' }
              });
              this.isSubmitted=true;
              this.dialogRef.close(true);
              
         },this.handleError);

      }

      handleError(error:any){
        this.isSpinnerVisible=false;
        console.log(error);
      }
      
      fileChange(event) {
        let files: FileList = event.target.files;
        if(files.length > 1) {
          // show error for more than 2 files
          this.fileList = null;
          this.hasFileValidationError=true;
          this.fileErrorMessage='Please select only one file.';
        }
        else if(files[0].size > MaxFileSize){
            // show file size error
            this.hasFileValidationError=true;
            this.fileErrorMessage='File size should be less than 2mb. ';
        }
        else{
          this.hasFileValidationError=false;
          this.fileErrorMessage='';
          this.fileList=files;
        }
      }

      parseResponse(response:any, key='response_data'):any {
        let success = false;
        let message = 'Something went wrong!';
        let data:any = {};
        if(response){
          success = response['status_code'] == 200;
          data = response[key];
          message = data ? data['msg'] : response['message'];
        }
        return {success, message,data};
      }

      showDialog(message){
        this.dialog.open(FinanceDialogDynamicComponent,
          {
              width: '450px',
              data: {message}
          });
      }
     
}
// popup end





<div fxLayout="row wrap">
    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div class="text-right m-r-0 m-b-25" style="margin-top: 5px!important;">
                    <div (click)="backPage()" mat-icon-button class="close-button" [mat-dialog-close]="true">
                        <mat-icon class="close-icon" color="warn">close</mat-icon>
                    </div>
                </div>

                <div fxLayout="row wrap">
                    <div fxFlex.gt-lg="66" fxFlex.gt-md="66" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-card-title class="m-l-0 m-t-10">Direct Deal Order Details :</mat-card-title>
                    </div>

                    <div class="Revenue-invoicing-mobile" fxFlex.gt-lg="17" fxFlex.gt-md="17" fxFlex.gt-xs="70"
                        fxFlex="100">
                        <mat-card-title style="cursor: pointer;" class="text-right Revenue-invoicing-mobileposition">
                            <button mat-stroked-button color="" id="" (click)="selectAllDialog()"
                                class=" bg-danger text-white">Set eCPM</button>
                        </mat-card-title>
                    </div>

                    <div class="Revenue-invoicing-mobile" fxFlex.gt-lg="17" fxFlex.gt-md="17" fxFlex.gt-xs="30"
                        fxFlex="100">
                        <mat-card-title style="cursor: pointer;" class="text-right Revenue-invoicing-mobileposition">
                            <button mat-stroked-button (click)="downloadExlOrder()" class="bg-danger text-white"> Download Report</button>
                        </mat-card-title>
                    </div>
                </div>

                <div class="p-5 m-t-20 p-r-20 p-l-20" style="background-color: aliceblue;">
                    <mat-form-field>
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                    </mat-form-field>
                </div>

                <div class="table-responsive m-t-5">
                    <table mat-table [dataSource]="dataSource" matSort class="no-wraap m-t-30 v-middle"
                        style="width: 100%;">
                        <ng-container matColumnDef="order_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">Order Start Date </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal">{{row.order_date}}</td>
                        </ng-container>

                        <ng-container matColumnDef="order_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Order Id </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal text-right">{{row.order_id}}</td>
                        </ng-container>

                        <ng-container matColumnDef="campaign_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Campaign Date </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal">{{row.campaign_date}}</td>
                        </ng-container>

                        <ng-container matColumnDef="order_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Order Name </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal breaklinetabledeal">{{row.order_name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="ad_unit_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Ad Unit Id </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal text-right">{{row.ad_unit_id}}</td>
                        </ng-container>

                        <ng-container matColumnDef="ad_unit_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Ad Unit Name </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal breaklinetabledeal">{{row.ad_unit_name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="set_cpm">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Set CPM </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal text-right">{{row.set_cpm}}</td>
                        </ng-container>

                        <ng-container matColumnDef="global_impression">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Total Impressions
                            </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal text-right">{{row.global_impression}}</td>
                        </ng-container>

                        <ng-container matColumnDef="actual_ecpm">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Actual eCPM </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal text-right">{{row.actual_ecpm}}</td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">Action</th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal" [style.color]="row.color">
                                <a (click)="editDialog(row.order_id,row.ad_unit_id,row.cam_date,row.set_cpm)" matTooltip="Edit" mat-icon-button>
                                    <mat-icon class="text-danger p-0">edit</mat-icon>
                                </a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
                </div>
            </mat-card-content>
            <div class="bg-opacity" *ngIf="isSpinnerVisible">
                <div class="parent">
                    <div class="spin1"></div>
                </div>
            </div>
        </mat-card>
    </div>
</div>
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../global/app-url';

interface myData {
    success: boolean,
    pub_name: string
    
  }
@Injectable({
    providedIn: 'root'
})
export class InvoiceUploadService {
    constructor(private http: HttpClient) {}

    
    getInvoiceList(){
        var t = localStorage.getItem('access_token');
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/getinvoiceListStatus.php', {
            uniq_id,
         },httpOptions)
    }
    getInvoiceMonth(){
        var t = localStorage.getItem('access_token');
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/getInvmonth.php', {
            uniq_id,
         },httpOptions)
    }
    getPublisherList(acc_manager:any){
        var t = localStorage.getItem('access_token');
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/getPublist.php', {
            uniq_id,
            acc_manager
         },httpOptions)
    }
    getfilterPublisherList(pub_uniq_id:any,month_year:any){
        var t = localStorage.getItem('access_token');
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/getinvoiceListStatus.php', {
            uniq_id,
            pub_uniq_id,
            month_year
         },httpOptions)
    }
    getAllPublisherList(){
        var t = localStorage.getItem('access_token');
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/getAllPubList.php', {
            uniq_id,
         },httpOptions)
    }
    uploadFiles(pub_uniq_id:string,child_net_code:string,invoice_id:string,invoice_month:string,login_uniq_id:string,file:File,operation_type,finance_invoice_id:string,upload:string){
        var t = localStorage.getItem('access_token');
        let formData:FormData = new FormData();
        formData.append('fileToUpload', file, file.name);  
        formData.append('login_uniq_id', login_uniq_id);
        formData.append('pub_uniq_id',pub_uniq_id);
        formData.append('invoice_id',invoice_id);
        formData.append('month_year',invoice_month);
        formData.append('child_net_code',child_net_code);
        formData.append('finance_invoice_id',finance_invoice_id);
        formData.append('upload',upload);
        formData.append('operation_type',operation_type);
       
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        headers_object.append('Content-Type', 'multipart/form-data');
       
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/invoice_upload.php', formData, httpOptions)
    }

    readPdf(finance_invoice_id:any){
        var t = localStorage.getItem('access_token');
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/update_read_invoice_pdf.php', {
            uniq_id,
            finance_invoice_id 
         },httpOptions)
    }

    approveInvoice(pub_uniq_id:any,finance_invoice_id:any,status:any){
        var t = localStorage.getItem('access_token'); 
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };

        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/finance_invoice_status_update.php', {
            uniq_id,
            pub_uniq_id,
            finance_invoice_id,
            status:'1'
         },httpOptions)
    }

    dispproveInvoice(pub_uniq_id:any,finance_invoice_id:any,status:any){
        var t = localStorage.getItem('access_token'); 
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };

        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/finance_invoice_status_update.php', {
            uniq_id,
            pub_uniq_id,
            finance_invoice_id,
            status:'2'
         },httpOptions)
    }
}
import { Component, Inject } from '@angular/core';
import { ActivityControlService } from './activity-control.service';
import { DialogDynamicAccComponent } from '../dialog-dynamic/dialog-dynamic.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { EditActivityData } from './activity-data';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

declare var $: any;
const MaxFileSize =1024000;
// Popup start
@Component({
    selector: 'app-edit-activity-control',
    templateUrl: 'edit-activity-control.html'
})

export class EditActivityControlAccComponent  {
   // MaxFileSize=1000000;
    isSpinnerVisible: boolean;
    fileList:FileList;
    hasFileValidatinError =false;
    fileErrorMessage ='';
    login_uniq_id: string;
    private pub_uniq_id: string;
    domainId:number;
    activityId:string = '';
    activityStatus: string;
    activityDate:string;
    comments:string;
    details:string;
    remarks:string='';
    message:any;
    selectedValue : string;
    activity_file:string;
    activity_file_2:string;
    alreadyExistData:string = "No";
    minDate = new Date();
    isBusy=false;
    isEdit=false;
    isChanged=false;
    type:string;
    constructor(
        public dialogRef: MatDialogRef<EditActivityControlAccComponent>,
        @Inject(MAT_DIALOG_DATA) public model: EditActivityData, private route:ActivatedRoute,public dialog: MatDialog,private _activityControlService:ActivityControlService
    ) { 
          this.pub_uniq_id=model.pub_unique_id;     
          this.domainId=model.domain_id;     
          this.activityId=model.activity_id; 
          this.type=model.type;   
          this.login_uniq_id=localStorage.getItem('uniq_id');
          this.activityDate=new Date().toDateString();

          if(model.data && !(model.data instanceof Array)){
           // this.activityStatus=model.data.activity_status;
            this.activityDate=model.data.activity_date;
            this.comments=model.data.activity_comments;
            this.details=model.data.comment_details;
            this.remarks=model.data.remarks;
            this.activity_file=model.data.activity_file;
            this.activity_file_2=model.data.activity_file_2;
            this.isEdit=model.data.activity_date!=null && model.data.activity_date!='';
          }
    }
  formChangeSubscription:Subscription;
  ngOnInit(): void {
   
  }
    onNoClick(): void {
        this.dialogRef.close(false);
    }
    onFormChange(){
      this.isChanged=true;
      console.log('valuechanges');
    }
    onCommentsChange(){
      this.details='';
    }
    onSubmit(f:NgForm){  
        
      let d = this.isChanged?new Date(): new Date(this.activityDate);
      let selectedDate = d.getFullYear()+'-'+ (d.getMonth()+1) +'-'+ d.getDate();
      if(f.invalid || this.hasFileValidatinError){
        return;
      }
      if(this.isBusy){
        return;
      }
      this.isBusy=true;
      this.isSpinnerVisible = true;    
      this._activityControlService  
        .upsertActivityControl(this.domainId,this.activityId,this.pub_uniq_id,this.login_uniq_id,
          selectedDate,this.comments,this.details,this.remarks,this.type)
        .subscribe((response:any)=>{  
          let resp = this.parseResponse(response);
          if(!resp.success){
            this.isBusy=false;
            this.isSpinnerVisible=false;
            this.showDialog(resp.message);
            return;
          }
          if(!this.fileList || this.fileList.length == 0){
            this.isBusy=false;
            this.isSpinnerVisible=false;
            this.showDialog(resp.message);
            this.dialogRef.close(true);
            return;
          }

          // let file = this.fileList[0];
          // this._activityControlService
          //     .uploadFiles(this.login_uniq_id,response['response_data']['id'],file,this.alreadyExistData)
          //     .subscribe((response2)=>{
          //       this.isBusy=false;
          //       this.isSpinnerVisible=false;
          //       let resp2= this.parseResponse(response2);
          //       this.showDialog(resp2.message == 'success' ? 'File Uploaded Successfully.' : 'File Upload Failed');
          //       this.dialogRef.close(true);
          //     },this.handleError);
        },this.handleError);
      }

      handleError(error:any){
        this.isBusy=false;
        this.isSpinnerVisible=false;
        console.log(error);
      }
      
      fileChange(event) {
        let files: FileList = event.target.files;
        if(files.length > 1) {
          // show error for more than 2 files
          this.fileList = null;
          this.hasFileValidatinError=true;
          this.fileErrorMessage='Please select only one file.';
        }
        else{
          this.hasFileValidatinError=false;
          this.fileErrorMessage='';
          this.fileList=files;
          if(files[0].size > MaxFileSize){
            // show file size error
            this.hasFileValidatinError=true;
            this.fileErrorMessage='File size should be less than 1mb. ';
            return;
          }
        }
      }

      parseResponse(response:any, key='response_data'):any {
        let success = false;
        let message = 'Something went wrong!';
        let data:any = {};
        if(response){
          success = response['status_code'] == 200;
          data = response[key];
          message = data ? data['msg'] : response['message'];
        }
        return {success, message,data};
      }

      showDialog(message){
        this.dialog.open(DialogDynamicAccComponent,
          {
              width: '450px',
              data: {message}
          });
      }
     
}
// popup end





import { Component, ElementRef, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogDynamicComponent } from '../../dialog-dynamic/dialog-dynamic.component';
import { ServiceinvoiceService } from '../serviceinvoice.service';
import { DialogUploadInvoiceListComponent } from './dialog-upload-invoice-list';

declare var $: any;
const MaxFileSize =2048000;
// Popup start
@Component({
    selector: 'app-dialog-upload-invoice',
    templateUrl: 'dialog-upload-invoice.html'
})

export class DialogUploadInvoiceComponent  {
    isSpinnerVisible: boolean;
    fileList:FileList;
    hasFileValidationError =false;
    fileErrorMessage ='';
    login_uniq_id: string;
    invoice_month:string;
    message:any;
    child_net_code:string;
    isBusy=false;
    isEdit=false;
    isChanged=false;
    type:string;
    status: any;
    isSubmitted=false;
   
    constructor(
        public dialogRef: MatDialogRef<DialogUploadInvoiceComponent>,
        @Inject(MAT_DIALOG_DATA) public model: any,public _UploadService:ServiceinvoiceService,private router: Router,public dialog: MatDialog
    ) { 
        // this.pub_uniq_id=model.pubUniqId;     
          this.child_net_code=localStorage.getItem('child_net_code');     
          this.invoice_month=model.month;
          this.login_uniq_id=localStorage.getItem('uniq_id');
         
    }
    invoiceUploadListDialog(data:any): void {
      const dialogRef = this.dialog.open(DialogUploadInvoiceListComponent, {
        width: '1000px',
        data: data
      });
  
      // dialogRef.afterClosed().subscribe(result => {
      //   //this.callbackfuntion();
      //   //console.log(result);
      //   if(dialogRef.componentInstance.isSubmitted){
      //     this.filterFunctn();
      //   }
      //   //console.log('The dialog was closed');
      // });
    }
  formChangeSubscription:Subscription;
  ngOnInit(): void {
   this.isSubmitted=false;
  }

    onNoClick(): void {
        this.dialogRef.close(false);
    }
    onFormChange(){
      this.isChanged=true;
    }
    @ViewChild('fileUploader') fileUploader:ElementRef;
    onSubmit(f:NgForm){ 
      if(this.hasFileValidationError || !this.fileList || this.fileList.length == 0){
        this.hasFileValidationError=true;
        this.fileErrorMessage='Please select a file';
        this.fileUploader.nativeElement.value = null;
        return;
      }
      this.isSpinnerVisible = true;    
      this._UploadService  
         .uploadFiles(this.child_net_code,this.invoice_month,this.login_uniq_id,this.fileList[0])
         .subscribe((response:any)=>{  
        //  console.log(response);
            this.isSpinnerVisible=false;
            let resp = this.parseResponse(response);
            if(!resp.success){
              if(Array.isArray(resp.message)){
              this.invoiceUploadListDialog(resp.message);
              return;
              }
               this.showDialog(resp.message);
              return;
            }

            if(!this.fileList || this.fileList.length == 0){
              this.showDialog(resp.message);
              this.isSubmitted=true;
              this.dialogRef.close(true);
              return;
            }
            this.dialog.open(DialogDynamicComponent,
              {
                  width: '450px',
                  data: { message: 'Your '+this.invoice_month+' invoice has been uploaded successfully.' }
              });
              this.isSubmitted=true;
              this.dialogRef.close(true);
              
         },this.handleError);

      }
      handleError(error:any){
        this.isSpinnerVisible=false;
        //console.log(error);
      }
      
      fileChange(event) {
        let files: FileList = event.target.files;  
        if(files.length > 1) {
          // show error for more than 2 files
          this.fileList = null;
          this.hasFileValidationError=true;
          this.fileErrorMessage='Please select only one file.';
        }
        else if(files[0].size > MaxFileSize){
            // show file size error
            this.hasFileValidationError=true;
            this.fileErrorMessage='File size should be less than 2mb.';
        }
          else if(files[0].name.split('.').pop() != 'pdf'){
            // show file size error
            this.hasFileValidationError=true;
            this.fileErrorMessage='Please upload your invoice in PDF format. ';
          }
          else{
            this.hasFileValidationError=false;
            this.fileErrorMessage='';
            this.fileList=files;
          }
      }

      parseResponse(response:any, key='response_data'):any {
        let success = false;
        let message = 'Something went wrong!';
        let data:any = {};
        if(response){
          success = response['status_code'] == 200;
          data = response[key];
          message = data ? data['msg'] : response['message'];
        }
        return {success, message,data};
      }

      showDialog(message){
        this.dialog.open(DialogDynamicComponent,
          {
              width: '450px',
              data: {message}
          });
      }
     
}
// popup end





import { Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { InvoiceUploadService } from './invoice-upload.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, NgForm } from '@angular/forms';
import moment from 'moment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Tracing } from 'trace_events';
import { FinanceDialogDynamicComponent } from '../finance-dialog-dynamic/finance-dialog-dynamic.component';

declare var $: any;
// Popup start
@Component({
    selector: 'app-dialog-invoice-upload-approve',
    templateUrl: 'dialog-invoice-upload-approve.html'
})

export class DialogInvoiceUploadApproveComponent {
  public accholdername:any;
  public pub_uniq_id:any;
  public invoice_id:any;
  public month:any;
  public message:any;
  isSpinnerVisible: boolean = false;
  status: any;
  finance_invoice_id: any;
  month_year: any;
  holder_name: any;
  constructor(
    public dialogRef: MatDialogRef<DialogInvoiceUploadApproveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,private fb: FormBuilder,private _invoiceService: InvoiceUploadService
  ) {
    this.pub_uniq_id = data.pubUniqId;
    this.invoice_id = data.invoiceId;
    this.finance_invoice_id=data.financeInvoiceId;
    this.month = data.month;
    this.status=data.status;
    this.month_year=data.month_year;
    this.holder_name=data.holdername;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  approveSubmit(){
    if(this.pub_uniq_id!=null && this.finance_invoice_id!=null && this.status!=null){
      this.isSpinnerVisible = true;
      this._invoiceService.approveInvoice(this.pub_uniq_id,this.finance_invoice_id,this.status).subscribe(dataResult => {
        if (dataResult['status_code'] == '200') {
          this.onNoClick();
          this.message = "Status approved successfully";
          this.dialog.open(FinanceDialogDynamicComponent,
              {
                  width: '450px',
                  data: { message: this.message }
              });
          this.isSpinnerVisible = false;
          } else {
            this.onNoClick();
            this.message = "Status Not approved";
            this.dialog.open(FinanceDialogDynamicComponent,
                {
                    width: '450px',
                    data: { message: this.message }
                });
            this.isSpinnerVisible = false;
          }
      });
    }else{
      
    }
    
  }
}
// popup end





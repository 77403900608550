import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../global/app-url';

interface myData {
    success: boolean,
    pub_name: string
    
  }
@Injectable({
    providedIn: 'root'
})
export class ActivityControlService {
    constructor(private http: HttpClient) {}

    getDomainActivityData(uniq_id:any ) {
        var t = localStorage.getItem('access_token');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/acc_mgr/get_domain_and_activity_list.php', {
            uniq_id,
        }, httpOptions)

    }

    getTableData(uniq_id:any,domain_id:any,type:string)
    {
        var t = localStorage.getItem('access_token');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/acc_mgr/get_domain_activity_detail_list.php', {
            uniq_id,
            domain_id,
            type
        }, httpOptions);
    }
    
    getFullData(domaniId:string,activityId:string,pub_uniq_id:string,login_uniq_id:string) {
        var t = localStorage.getItem('access_token');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/acc_mgr/add_acitivity_control.php', {
            'domain_id':domaniId,
            'activity_id':activityId,
             pub_uniq_id,
             login_uniq_id,
            "operation_type" : "Fetch",
        }, httpOptions)

    }

        upsertActivityControl(domaniId:number,activityId:string,pub_uniq_id:string,login_uniq_id:string,
        activity_date:string,comments:string,details:string,remarks:string,type:string){
        var t = localStorage.getItem('access_token');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        if(!details){
            details='';
        }
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/acc_mgr/add_acitivity_control.php', {
           'domain_id':domaniId,
           'activity_id':activityId,
           details,
            pub_uniq_id,
            login_uniq_id,
           // status,
            activity_date,
            comments,
            remarks,
            type
        }, httpOptions)

    }

     uploadFiles(login_uniq_id:string,domainId:string,pub_uniq_id:string,file:File,type:string){
        var t = localStorage.getItem('access_token');
        let formData:FormData = new FormData();
        formData.append('fileToUpload', file, file.name);  
        formData.append('login_uniq_id', login_uniq_id);
        formData.append('domain_id',domainId);
        formData.append('pub_uniq_id',pub_uniq_id);
        formData.append('type',type);
       
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        headers_object.append('Content-Type', 'multipart/form-data');
       
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/acc_mgr/upload_activity_control_doc.php', formData, httpOptions)
    }
}
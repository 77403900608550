<div fxLayout="row wrap">
    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap">
                    <div fxFlex.gt-lg="66" fxFlex.gt-md="66" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-card-title class="m-l-0 m-t-10">Payments :</mat-card-title>
                    </div>

                    <div class="Revenue-invoicing-mobile" fxFlex.gt-lg="17" fxFlex.gt-md="17" fxFlex.gt-xs="70" fxFlex="100">
                        <mat-card-title style="cursor: pointer;" class="text-right Revenue-invoicing-mobileposition">
                            <button mat-stroked-button color="" id="" [routerLink]="['../finance-payment-history']"
                                class=" bg-danger text-white"> Payments History</button>
                        </mat-card-title>
                    </div>

                    <div class="Revenue-invoicing-mobile" fxFlex.gt-lg="17" fxFlex.gt-md="17" fxFlex.gt-xs="30" fxFlex="100">
                        <mat-card-title style="cursor: pointer;" class="text-right Revenue-invoicing-mobileposition">
                            <button mat-stroked-button (click)="exportTablecsv()"
                                class=" bg-danger text-white"> Export table to CSV</button>
                        </mat-card-title>
                    </div>
                </div>
                
                <div class="p-5 m-t-20 p-r-20 p-l-20" style="background-color: aliceblue;">
                    <mat-form-field>
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                    </mat-form-field>
                </div>
                <div fxLayout="row wrap">
                    <div fxFlex.gt-sm="100" fxFlex="100">
                        <form class="m-t-30  p-b-0" [formGroup]="findInvform">
                            <div fxLayout="row wrap" fxFlexAlign="center" class="row">
                                <div fxFlex.gt-sm="50" fxFlex.gt-lg="23" fxFlex.gt-md="23" fxFlex.gt-xs="100"
                                    fxFlex="100" class="p-10">
                                    <mat-form-field appearance="outline" class="p-r-0 m-b-f5">
                                        <mat-label>Month</mat-label>
                                        <mat-select matInput #fontSize [(ngModel)]="getInvmonth" value="16px" [formControl]="findInvform.controls['inv_month']">
                                            <mat-option value="January">January</mat-option>
                                            <mat-option value="February">February</mat-option>
                                            <mat-option value="March">March</mat-option>
                                            <mat-option value="April">April</mat-option>
                                            <mat-option value="May">May</mat-option>
                                            <mat-option value="June">June</mat-option>
                                            <mat-option value="July">July</mat-option>
                                            <mat-option value="August">August</mat-option>
                                            <mat-option value="September">September</mat-option>
                                            <mat-option value="October">October</mat-option>
                                            <mat-option value="November">November</mat-option>
                                            <mat-option value="December">December</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div fxFlex.gt-sm="50" fxFlex.gt-lg="23" fxFlex.gt-md="23" fxFlex.gt-xs="100"
                                    fxFlex="100" class="p-10">
                                    <mat-form-field appearance="outline" class="p-r-0 m-b-f5">
                                        <mat-label>Year</mat-label>
                                        <mat-select matInput #fontSize [(ngModel)]="getInvyear" value="16px" [formControl]="findInvform.controls['inv_year']">
                                            <mat-option value="2020">2020</mat-option>
                                            <mat-option value="2021">2021</mat-option>
                                            <mat-option value="2022">2022</mat-option>
                                            <mat-option value="2023">2023</mat-option>
                                            <mat-option value="2024">2024</mat-option>
                                            <mat-option value="2025">2025</mat-option>
                                            <mat-option value="2026">2026</mat-option>
                                            <mat-option value="2027">2027</mat-option>
                                            <mat-option value="2028">2028</mat-option>
                                            <mat-option value="2029">2029</mat-option>
                                            <mat-option value="2030">2030</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div fxFlex.gt-sm="50" fxFlex.gt-lg="8" fxFlex.gt-md="8" fxFlex.gt-xs="100" fxFlex="100"
                                    class="p-10 m-t-15 text-right">
                                    <button mat-stroked-button (click)="invoicefilter()" class="bg-danger text-white">Go</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="table-responsive">
                    <table mat-table [dataSource]="allInvList" matSort class="no-wraap m-t-10 v-middle"
                        style="width: 100%;">
                        <ng-container matColumnDef="month">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Month </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal">{{row.month}}</td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Company Name </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal breaklinetabletd1">{{row.holdername}}</td>
                        </ng-container>

                        <ng-container matColumnDef="address">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Company Address </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal breaklinetabletd">
                                {{row.holderaddress}}
                                <ng-container *ngIf="row.city!='' && row.city!='null' && row.city!=null">, {{row.city}}</ng-container>
                                <ng-container *ngIf="row.state!='' && row.city!='null' && row.city!=null">, {{row.state}}</ng-container>
                                <ng-container *ngIf="row.postal!='' && row.city!='null' && row.city!=null">, {{row.postal}}</ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="earning">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">Earnings</th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal text-right">
                                <ng-container *ngIf="chkInvDateforEar">
                                    $ {{row.dis_rev+row.app_rev+row.vid_rev+row.hbads-row.invalid_traffic | number:'1.2-2'}}
                                </ng-container>
                                <ng-container *ngIf="!chkInvDateforEar">
                                    <ng-container *ngIf="row.gst_num=='' || row.gst_num=='no'">
                                        Rs. {{(row.dis_rev+row.app_rev+row.vid_rev+row.hbads-row.invalid_traffic)*row.bcr | number:'1.2-2'}}
                                    </ng-container>
                                    <ng-container *ngIf="row.gst_num!='' && row.gst_num!='no'">
                                        Rs. {{(row.dis_rev+row.app_rev+row.vid_rev+row.hbads-row.invalid_traffic)*row.bcr*1.18 | number:'1.2-2'}}
                                    </ng-container>
                                </ng-container>
                            </td>
                            
                        </ng-container>

                        <ng-container matColumnDef="invoice">
                            <th mat-header-cell *matHeaderCellDef class="font-medium font-16 p-20"> View </th>
                            <td mat-cell *matCellDef="let row" class="p-20 font-normal text-center action-link">
                                <ng-container *ngIf="row.dis_rev+row.hbads+row.vid_rev+row.app_rev-row.invalid_traffic > 1">
                                    <a *ngIf="row.invoice_status=='Paid' || row.invoice_status=='Payment Under Process'" (click)="downloadInv(row.pdf_name)" class="link login-weight colorblackky">
                                        <img matTooltip="Download" src="assets/images/users/pdf.png" height="40" width="40" class="img-fluid cursor-pointer"/>
                                    </a>
                                    <a *ngIf="row.invoice_status=='Approval Pending'" class="link login-weight colorblackky">
                                        <img matTooltip="Not Available" src="assets/images/users/pdfgray.png" height="40" width="40" class="img-fluid"/>
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="row.dis_rev+row.hbads+row.vid_rev+row.app_rev-row.invalid_traffic < 1">
                                    <a class="link login-weight colorblackky">
                                        <img matTooltip="Not Available" src="assets/images/users/pdfgray.png" height="40" width="40" class="img-fluid"/>
                                    </a>
                                </ng-container>
                               
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Status </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal" [style.color]="row.color">
                                <ng-container *ngIf="row.dis_rev+row.hbads+row.vid_rev+row.app_rev-row.invalid_traffic > 1">
                                    <p *ngIf="row.invoice_status=='Payment Under Process'" class="text-danger">{{row.invoice_status}}</p>
                                    <p *ngIf="row.invoice_status=='Paid'" class="text-success">{{row.invoice_status}}</p>
                                    <p *ngIf="row.invoice_status=='Approval Pending'" class="text-inverse">{{row.invoice_status}}</p>
                                </ng-container>
                                <ng-container *ngIf="row.dis_rev+row.hbads+row.vid_rev+row.app_rev-row.invalid_traffic < 1">
                                    <p class="text-inverse">No Billing</p>
                                </ng-container>
                                <!--  -->
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef class="font-medium font-16 p-15">Action</th>                            
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal" [style.color]="row.color">
                                <button mat-stroked-button *ngIf="row.invoice_status=='Payment Under Process'" (click)="paymentDoneDialog(row.holdername,row.pub_uniq_id,row.id,row.month)" class="bg-success text-white" disabled> Payment Done</button>
                                <p *ngIf="row.invoice_status=='Paid'" class="text-success">Paid</p>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
                </div>
            </mat-card-content>
            <div class="bg-opacity" *ngIf="isSpinnerVisible">
                <div class="parent">
                    <div class="spin1"></div>
                </div>
            </div>
        </mat-card>
    </div>
</div>
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../global/app-url';

interface myData {
    success: boolean,
    pub_name: string
    
  }
@Injectable({
    providedIn: 'root'
})
export class InvoiceService {
    constructor(private http: HttpClient) {}
 
    getInvoiceList(){
        var t = localStorage.getItem('access_token');
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/getinvoiceListStatus.php', {
            uniq_id,
         },httpOptions)
    }
    getInvoiceMonth(){
        var t = localStorage.getItem('access_token');
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/getInvmonth.php', {
            uniq_id,
         },httpOptions)
    }
    getPublisherList(acc_manager:any){
        var t = localStorage.getItem('access_token');
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/getPublist.php', {
            uniq_id,
            acc_manager
         },httpOptions)
    }
    getfilterPublisherList(pub_uniq_id:any,month_year:any){
        var t = localStorage.getItem('access_token');
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/getinvoiceListStatus.php', {
            uniq_id,
            pub_uniq_id,
            month_year
         },httpOptions)
    }
    getAllPublisherList(){
        var t = localStorage.getItem('access_token');
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/getAllPubList.php', {
            uniq_id,
         },httpOptions)
    }
    sendMail(pub_uniq_id:any,invoice_id:any,finance_invoice_id:any,month:any){
        var t = localStorage.getItem('access_token'); 
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };

        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/payment_status_update.php', {
            uniq_id,
            pub_uniq_id,
            invoice_id,
            finance_invoice_id,
            month,
            finalApprove:'true'
         },httpOptions)
    }
    approveInvoice(pub_uniq_id:any,finance_invoice_id:any,status:any){
        var t = localStorage.getItem('access_token'); 
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };

        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/finance_invoice_status_update.php', {
            uniq_id,
            pub_uniq_id,
            finance_invoice_id,
            status:'1',
         },httpOptions)
    }

    dispproveInvoice(pub_uniq_id:any,finance_invoice_id:any,status:any){
        var t = localStorage.getItem('access_token'); 
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };

        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/finance_invoice_status_update.php', {
            uniq_id,
            pub_uniq_id,
            finance_invoice_id,
            status:'4'
         },httpOptions)
    }
    readPdf(finance_invoice_id:any,read_flag_status:any){
        var t = localStorage.getItem('access_token');
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/finance/update_read_invoice_pdf.php', {
            uniq_id,
            finance_invoice_id,
            read_flag_status
         },httpOptions)
    }
}
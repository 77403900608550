import { Component, OnInit,Inject, Optional,Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-dialog-dynamic-content-insights',
  templateUrl: './dialog-dynamic.component.html',
  styleUrls: ['./dialog-dynamic.component.scss']
})
export class DialogDynamicComponentcontentinsights implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogDynamicComponentcontentinsights>,
        // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  ngOnInit(): void {
  }
  onOkClick(): void {
    this.dialogRef.close('OK');
  }

  @Output() closeEvent = new EventEmitter<void>();

  onClose() {
    this.closeEvent.emit();
  }
  downloadDocs() {
    const a = document.createElement('a');
    a.setAttribute('href', '/assets/images/document/ga4_steps.docx');
  a.setAttribute('download', 'ga4_steps.docx');  // You can name your downloaded file here
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
}

import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../global/app-url';

interface myData {
    success: boolean,
    pub_name: string
    
  }
@Injectable({
    providedIn: 'root'
})
export class ManageAdsTxtService {
    constructor(private http: HttpClient) {}

    getAdsTxtList(){
        var t = localStorage.getItem('access_token'); 
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };

        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/adstxt/getadstxt.php', {
            uniq_id,
         },httpOptions)
    }
    addAdsTxtForm(network_acc_id:any,network_domain:any,acc_type:any,authority_id:any){
        var t = localStorage.getItem('access_token'); 
        var uniq_id = localStorage.getItem('uniq_id');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };

        return this.http.post(AppSettings.API_STARTPOINT+'/assets/api/admin/adstxt/addupdateadstxt.php', {
            uniq_id,
            network_acc_id,
            network_domain,
            acc_type,
            authority_id
         },httpOptions)
    }
    
}
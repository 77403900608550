<div fxLayout="row wrap">
    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap">
                    <div fxFlex.gt-lg="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-card-title class="m-l-0 m-t-10">Direct Deal Order Details :</mat-card-title>
                    </div>

                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-card-title style="cursor: pointer;" class="text-right">
                            <!-- <button mat-stroked-button color="" id=""
                                class=" bg-danger text-white"> Export table to CSV</button> -->
                        </mat-card-title>
                    </div>
                </div>

                <div class="p-5 m-t-20 p-r-20 p-l-20" style="background-color: aliceblue;">
                    <mat-form-field>
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                    </mat-form-field>
                </div>

                <div class="table-responsive m-t-5">
                    <table mat-table [dataSource]="dataSource" matSort class="no-wraap m-t-30 v-middle"
                        style="width: 100%;">
                        <ng-container matColumnDef="order_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Order Start Date </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal">{{row.order_date}}</td>
                        </ng-container>

                        <ng-container matColumnDef="order_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Order Id </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal text-right">{{row.order_id}}</td>
                        </ng-container>

                        <ng-container matColumnDef="order_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Order Name </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal breaklinetabledeal">{{row.order_name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="set_cpm">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Set CPM</th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal text-right">{{row.set_cpm}}</td>
                        </ng-container>

                        <ng-container matColumnDef="direct_expense">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">Direct Expense </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal text-right">{{row.direct_expense}}</td>
                        </ng-container>

                        <ng-container matColumnDef="global_impression">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Total Impressions
                            </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal text-right">{{row.global_impression}}</td>
                        </ng-container>

                        <ng-container matColumnDef="ecpm">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Actual eCPM </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal text-right">{{row.ecpm}}</td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Action</th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal" [style.color]="row.color">
                                <a matTooltip="View Direct Deal" mat-icon-button
                                    [routerLink]="['../deals-direct-deal-view',row.order_id]">
                                    <mat-icon class="text-danger p-0">remove_red_eye</mat-icon>
                                </a> <span class="colorblackky cursor-pointer"
                                    [routerLink]="['../deals-direct-deal-view',row.order_id]">View Direct
                                    Deal</span>
                                <br>
                                
                                <button mat-stroked-button color="" id="" (click)="addDialog(row.order_id,row.direct_expense)" class="bg-success text-white m-t-10 m-b-10">Update Direct Expense</button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
                </div>
            </mat-card-content>
            <div class="bg-opacity" *ngIf="isSpinnerVisible">
                <div class="parent">
                    <div class="spin1"></div>
                </div>
            </div>
        </mat-card>
    </div>
</div>
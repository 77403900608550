<div mat-icon-button class="close-button m-t-5" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</div>
<h2 class="font-bold m-l-10"  mat-dialog-title><strong style="margin-left: -5px;">Upload Invoice</strong></h2>

<form class="m-t-0  p-b-0" #f="ngForm" (submit)="onSubmit(f)"  >
  <mat-dialog-content>
    <div fxLayout="row wrap" style="margin-top: -10px!important">
    
      <div fxFlex.gt-sm="20" fxFlex="100" class="p-t-10" >
        <mat-label > Choose File*</mat-label>
      </div> 

      <div fxFlex.gt-sm="80" fxFlex="100">
        <input class="form-control b-b" #fileUploader placeholder="file"  type="file" accept=".pdf" (change)="fileChange($event)" required>
        <div *ngIf="hasFileValidationError">
          <small class="text-danger support-text logintrp-css spacing-adjust m-b-20 m-t-10 customWidth">
            {{fileErrorMessage}}
          </small>
        </div>
      </div> 

      <div fxFlex.gt-sm="100" fxFlex="100" class="p-10">
        <div class="p-r-0 m-b-10 m-t-5 text-center">
          <button mat-stroked-button color="" id="" class=" bg-danger text-white" >Submit</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <!---Loader Start-->
  <div class="bg-opacity" *ngIf="isSpinnerVisible">
    <div class="parent">
      <div class="spin1"></div>
    </div>
  </div>
  <!---Loader End-->
</form>
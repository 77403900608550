<!-- <div mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon  class="close-icon" color="warn">close</mat-icon>
  </div>
  <mat-dialog-content class="pb-4">
    <div fxLayout="row wrap">
   
    <div fxFlex="100" fxFlex.gt-md="100" fxFlex.gt-xs="100">
      <h4 class="m-l-10 m-r-10 popuplineheight" align="center">
        Are yor sure to change Payment Status <strong>{{accholdername}}</strong><br><br>
        <button class="bg-danger text-white" mat-button mat-button (click)="confirmSubmit()" tabindex="-1">Confirm</button>
        <button class="bg-danger text-white" mat-button (click)="onNoClick()" tabindex="-1">Close</button>
      </h4> 
    </div>
    
  </div>
  </mat-dialog-content>
  <div class="bg-opacity" *ngIf="isSpinnerVisible">
    <div class="parent">
        <div class="spin1"></div>
    </div>
</div> -->
<div mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</div>

<div fxLayout="row wrap">
  <div fxFlex="100" fxFlex.gt-md="100" fxFlex.gt-xs="100">
    <h4 class="m-l-10 m-r-10 popuplineheight" align="center">
      Are yor sure to change the Payment Status for <strong>{{accholdername}}</strong>
    </h4>
  </div>
</div>

<div mat-dialog-actions align="center" class="m-b-0 btmspacing1">
  <mat-card-actions class="btmspacing2">
    <a class="m-r-15">
      <button mat-stroked-button (click)="confirmSubmit()" class="m-b-10 m-r-10 bg-danger text-white">OK</button>
    </a>

    <a class="m-r-15">
      <button mat-stroked-button (click)="onNoClick()" class="m-b-10 m-r-10 bg-danger text-white">Cancel</button>
    </a>
  </mat-card-actions>
</div>
<div class="bg-opacity" *ngIf="isSpinnerVisible">
  <div class="parent">
      <div class="spin1"></div>
  </div>
</div>
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ManageAdsTxtService } from './Manageads-txt.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, TooltipPosition } from '@angular/material/tooltip';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminDialogDynamicComponent } from '../dialog-dynamic/admin-dialog-dynamic.component';

// Popup Add User start
@Component({
  selector: 'app-dialog-add-users-dialog',
  templateUrl: 'dialog-add-users.html'
})

export class DialogAddUsersManageAdsTxtAdminComponent {
  @ViewChild('closebutton') closebutton;
  message1: string;
  isSpinnerVisible1:boolean=false;

  public adstxtform: FormGroup = Object.create(null);
  constructor(
    public dialogRef: MatDialogRef<DialogAddUsersManageAdsTxtAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,private fb: FormBuilder
  , private _adstxtService: ManageAdsTxtService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  this.adstxtform = this.fb.group({
      net_domain: [null, Validators.compose([Validators.required,
      Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")])],
      acc_id: [null, Validators.compose([Validators.required])],
      acc_type: [null, Validators.compose([ Validators.required])],
      authority_id: [null]
     });
   
    }

adstxtSubmit() {
    
    const net_domain = this.adstxtform.value.net_domain;
    const acc_id = this.adstxtform.value.acc_id;
    const acc_type = this.adstxtform.value.acc_type;
    const authority_id = this.adstxtform.value.authority_id;

    if (this.adstxtform.invalid) {
      return;
    }
    if (this.adstxtform.valid) {
      this.isSpinnerVisible1 = true;
      this._adstxtService.addAdsTxtForm(acc_id,net_domain,acc_type,authority_id).subscribe(data => {
        this.isSpinnerVisible1 = false;
        if (data['status_code'] == '200') {
          this.adstxtform.reset();
          this.message1 = data['message'];
          this.dialog.open(AdminDialogDynamicComponent,
            {
              width: '450px',
              data: { message: this.message1 }
            });
        } else {
          this.message1 = data['message'];
          this.dialog.open(AdminDialogDynamicComponent,
            {
              width: '450px',
              data: { message: this.message1 }
            });
        }

      });
    }
  }
}
// popup Add User end

// Tooltip start
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 1000,
  touchendHideDelay: 1000,
};
// tool tip end

const COLORS = [
  '#27cb71e3',
  '#e94265',
  '#a9a9a9'
];

const NAMES = [
  'Disapproved_Duplicate_Account',
  'Approved',
  'Expired',
  'InActive'
];

const services = [

]

@Component({
  selector: 'app-Manageads-txt',
  templateUrl: './Manageads-txt.component.html',
  styleUrls: ['./Manageads-txt.component.scss'],
  // Tooltip start
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }
  ],
  // tool tip end 
})

export class ManageTxtComponent implements AfterViewInit {

  message: string;
  isSpinnerVisible:boolean=false;

 
  displayedColumns = ['network_domain', 'network_acc_id', 'acc_type', 'authority_id'];
  dataSource: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);
  @ViewChild(MatSort, { static: true }) sort: MatSort = Object.create(null);

  // Popup start
  animal = '';
  name = '';
  // popup end

  constructor(breakpointObserver: BreakpointObserver, private fb: FormBuilder, private location: Location, public dialog: MatDialog, private router: Router, private _adstxtService: ManageAdsTxtService) {

    breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['network_domain', 'network_acc_id', 'acc_type', 'authority_id'] :
        ['network_domain', 'network_acc_id', 'acc_type', 'authority_id'];
    });

    // const users: UserData[] = [];
    // for (let i = 1; i <= 100; i++) {
    //   users.push(createNewUser(i));
    // }

    // this.dataSource = new MatTableDataSource(users);
  }
ngOnInit() {
  this.isSpinnerVisible = true;
         this._adstxtService.getAdsTxtList().subscribe((response)=>{
            if(response['status_code']=='200'){
                 this.dataSource = new MatTableDataSource(response['adstxtData']);
                 this.dataSource.paginator = this.paginator;
                 this.dataSource.sort = this.sort;
                 this.isSpinnerVisible = false;
               }else if(response['status_code']=='204'){
                this.message = "No Data Found!"; 

                this.dialog.open(AdminDialogDynamicComponent,
                      { 
                        width: '450px',
                        data:  {message:this.message}
                      });
                this.isSpinnerVisible = false;
               }
            else{
                this.message = response['message']; 

                this.dialog.open(AdminDialogDynamicComponent,
                      { 
                        width: '450px',
                        data:  {message:this.message}
                      });
                this.isSpinnerVisible = false;
               }
            
          })
       

   
    }

  // Popup Add User start
  addDialog(): void {
    const dialogRef = this.dialog.open(DialogAddUsersManageAdsTxtAdminComponent, {
      width: '900px',
      data: { name: this.name, animal: this.animal }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }
  // popup Add User end

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

}


// function createNewUser(id: number): UserData {
//   const name =
//     NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
//     ' ' +
//     NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
//     '.';

//   return {
//     name: name,
//     progress: Math.round(Math.random() * 100).toString(),
//     color: COLORS[Math.round(Math.random() * (COLORS.length - 1))],
//     status: name,
//   };
// }

export interface UserData {
  network_domain: string;
  network_acc_id: string;
  acc_type: string;
  authority_id: string;
}


<div fxLayout="row wrap">
  <div fxFlex="100">
      <mat-card>
          <mat-card-content *ngIf="isSpinnerVisible">
          <div class="bg-opacity" *ngIf="isSpinnerVisible">
              <div class="parent">
                  <div class="spin1"></div>
              </div>
          </div>
          </mat-card-content>
          <mat-card-content >
              <div class="text-right m-r-0 m-b-25" style="margin-top: 5px!important;">
                  <div (click)="onNoClick()" mat-icon-button class="close-button"
                      [mat-dialog-close]="true">
                      <mat-icon class="close-icon" color="warn">close</mat-icon>
                  </div>
              </div>
              <!-- <mat-card-title class=" m-t-20 p-b-35"> Details</mat-card-title> -->
              <div class="table-responsive">
                  <table mat-table [dataSource]="invoiceList" matSort class="no-wrap m-t-0 v-middle">
                      <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef class="font-medium font-16 p-15">
                              Details </th>
                          <td mat-cell *matCellDef="let element" class="p-15 font-normal"> {{element.name}}
                          </td>
                      </ng-container>


                      <ng-container matColumnDef="correct_value">
                          <th mat-header-cell *matHeaderCellDef class="font-medium font-16 p-15">
                              Correct Details</th>
                          <td mat-cell *matCellDef="let element" class="p-15 font-normal text-left">
                            <span [innerHtml]="element.correct_value"></span>
                           
                          </td>
                      </ng-container>


                      <ng-container matColumnDef="msg">
                          <th mat-header-cell *matHeaderCellDef class="font-medium font-16 p-15"> Status</th>
                          <td mat-cell *matCellDef="let element" class="p-15 font-normal">
                            <span *ngIf="element.msg=='Found'"><mat-icon class="text-success">check</mat-icon></span>
                            <span *ngIf="element.msg=='Not Found'"><mat-icon class="text-danger">close</mat-icon></span>
                            
                          </td>
                      </ng-container>
                      
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                  <span class="text-danger">Please upload the correct details</span>
                  <mat-card-footer style="margin-bottom: 60px!important;"></mat-card-footer>
              </div>
          </mat-card-content>
      </mat-card>
  </div>
</div>
<!-- Existing close button -->
<div mat-icon-button class="close-button" [mat-dialog-close]="true" mat-button [routerLink]="['/dashboard']">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</div>

<!-- Dialog Content -->
<div fxLayout="row wrap" class="dialog-content">
  <div fxFlex="100" fxFlex.gt-md="100" fxFlex.gt-xs="100">
    <!-- Google Analytics 4 Editorial Insights Setup -->
    <div class="ga4-setup">
      <h5 class="center-text">Google Analytics 4 Editorial Insights Setup</h5>
      <ol class="custom-list">
        <li>Step 1: Login to Google Analytics 4 Admin Account. Select the domain for which you would like to enable your GA4 setup.</li>
        <li>Step 2: Go to Admin (left navigation) > Account Access Management (centre section)</li>
        <li>Step 3: Click on the “+” sign on top right of the page. Provide the “Administrator” access to <span class="highlighted-email"><a href="mailto:auxoadsga4access@gmail.com">auxoadsga4access@gmail.com</a></span></li>
      </ol>
    </div>



    <!-- Justified and centered paragraph -->
    <div fxLayout="row" fxLayoutAlign="left left">
      <div fxFlex="100" fxFlex.gt-md="100" fxFlex.gt-xs="100">
        <div class="left-text">
         <ol class="custom-list">
          <li>Your dashboard should be ready in 48 hours. In case of issues, please email us at  <a href="mailto:techsupport@auxoads.com">techsupport@auxoads.com</a></li>
        </ol>
      </div>
    </div>
  </div>


  <!-- Centered Close button -->
  <div fxLayout="row" fxLayoutAlign="center center">
    <button *ngIf="!data.bankAcc && !data.googleAcc && !data.cancelText" class="bg-danger text-white" mat-button [mat-dialog-close]="true" [routerLink]="['/dashboard']" tabindex="-1">
      Close
    </button>
  </div>
</div>
</div>

<div fxLayout="row wrap">
    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap">
                    <div fxFlex.gt-lg="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-card-title class="m-l-0 m-t-10">User Information :</mat-card-title>
                    </div>

                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-card-title style="cursor: pointer;" class="text-right">
                            <button mat-stroked-button (click)="addDialog()" color="" id=""
                                class=" bg-danger text-white">Add Users</button>
                        </mat-card-title>
                    </div>
                </div>
                
                <div class="p-5 m-t-20 p-r-20 p-l-20" style="background-color: aliceblue;">
                    <mat-form-field>
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                    </mat-form-field>
                </div>

                <div fxLayout="row wrap">
                    <div fxFlex.gt-sm="100" fxFlex="100">
                        <form class="m-t-30  p-b-0" [formGroup]="filterform">
                            <div fxLayout="row wrap" fxFlexAlign="center" class="row">
                                <div fxFlex.gt-sm="50" fxFlex.gt-lg="23" fxFlex.gt-md="23" fxFlex.gt-xs="100"
                                    fxFlex="100" class="p-10">
                                    <mat-form-field appearance="outline" class="p-r-0 m-b-f5">
                                        <mat-label>User Role</mat-label>
                                        <mat-select (selectionChange)="customFilter()" matInput #fontSize value="16px" [formControl]="filterform.controls['user_role']">
                                            <mat-option *ngFor="let rolelist of accUserRolelist;" value="{{rolelist.role_id}}">
                                                {{rolelist.role_name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div fxFlex.gt-sm="50" fxFlex.gt-lg="23" fxFlex.gt-md="23" fxFlex.gt-xs="100"
                                    fxFlex="100" class="p-10">
                                    <mat-form-field appearance="outline" class="p-r-0 m-b-f5">
                                        <mat-label>User Status</mat-label>
                                        <mat-select matInput #fontSize (selectionChange)="customFilter()" value="16px" [formControl]="filterform.controls['user_status']">
                                            <mat-option value="Y">Active </mat-option>
                                            <mat-option value="N">InActive</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div fxFlex.gt-sm="50" fxFlex.gt-lg="8" fxFlex.gt-md="8" fxFlex.gt-xs="100" fxFlex="100"
                                    class="p-10 m-t-15 text-right">
                                    <button mat-stroked-button color="" (click)="resetFormfilter()" class="bg-danger text-white">Reset</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="table-responsive">
                    <table mat-table [dataSource]="accUserList" matSort class="no-wraap m-t-10 v-middle"
                        style="width: 100%;">
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Name </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal">{{row.name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="progress">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Account Users Email Id </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal">{{row.email}}</td>
                        </ng-container>

                        <ng-container matColumnDef="accountm">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                User Role </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal">{{row.role_name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="color">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                Account Users On Board</th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal">{{row.user_created_date}}</td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-medium font-16 p-15">
                                User Status </th>
                            <td mat-cell *matCellDef="let row" class="p-15 font-normal" [style.color]="row.color">
                                <p *ngIf="row.user_status=='Y'" class="text-success">Active</p>
                                <p *ngIf="row.user_status=='N'" class="text-danger">InActive</p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef class="font-medium font-16 p-20"> Action </th>
                            <td mat-cell *matCellDef="let element" class="p-20 font-normal text-center action-link">
                                <a  (click)="editDialog(element.id,element.email)" matTooltip="View / Update User Information"
                                    class="m-r-20 cursor-pointer">
                                    <i class="fa fa-pencil font-20 text-danger networkpartner-mobile"></i>
                                </a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                </div>
            </mat-card-content>
            <div class="bg-opacity" *ngIf="isSpinnerVisible">
                <div class="parent">
                    <div class="spin1"></div>
                </div>
            </div>
        </mat-card>
    </div>
</div>
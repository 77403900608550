import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HeaderService } from './publisherheader.service';
import { DialogDynamicComponent } from '../dialog-dynamic/dialog-dynamic.component';
import { NotificationService } from '../notification/notification.service';
import { Router } from '@angular/router';
import {
  NgForm
} from '@angular/forms';
// dialogue start

@Component({
  selector: 'app-vertical-header-dialog',
  templateUrl: 'publisher-dialog-inventory.html',
  styleUrls: ['./publisher-vertical-header.component.scss']
})
export class PublisherAppHeaderDialogComponent {
  isChecked1 = true;
  isChecked2 = false;
  isChecked3 = false;
  isChecked4 = false;
  isChecked5 = false;
  public acc_name = localStorage.getItem('acc_name');
  public useremail = localStorage.getItem('useremail');
  isSpinnerVisible: boolean = false;
  message: string;
  newsletter: boolean = false;
  old_service = [];
  

  constructor(
    public dialogRef: MatDialogRef<PublisherAppHeaderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _headerService: HeaderService, public dialog: MatDialog
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {

    this.pubService();
    
  }

  //pub services check
  pubService() {
    this.isSpinnerVisible = true;
    this._headerService.getPubService(localStorage.getItem('uniq_id'), localStorage.getItem('useremail')).subscribe((response) => {
      
      this.isSpinnerVisible = false;
      if (response['status_code'] == '200') {
        if (response['service'].length > 0) {

          response['service'].forEach((value) => {
            this.old_service.push(value['service_id']);
            if (value['service_id'] == 5) {
              this.isChecked2 = true;
            }
            if (value['service_id'] == 4) {
              this.isChecked3 = true;
            }
            if (value['service_id'] == 7) {
              this.isChecked4 = true;
            }
          });
        }
        if (response['news'] != null) {

          this.isChecked5 = true;
        }

      }



    })
  }

  invenSubmit(form: NgForm) {
    this.isSpinnerVisible = true;

    const servArr = [1, 2, 3];
    const servFalseArr = [];

    if (form.value.premium == true) {
      servArr.push(5);
    } else {
      servFalseArr.push(5);
    }
    if (form.value.fill == true) {
      servArr.push(4);
    } else {
      servFalseArr.push(4);
    }
    if (form.value.cdp == true) {
      servArr.push(7);
    } else {
      servFalseArr.push(7);
    }
    if (form.value.letter == true) {

      this.newsletter = true;
    } else {
      this.newsletter = false;
    }
    this._headerService.postInvenSerData(localStorage.getItem('uniq_id'), servArr, localStorage.getItem('useremail'), this.newsletter, this.acc_name, this.old_service, servFalseArr).subscribe((response) => {

      
      if (response['status_code'] == '201') {
        this.dialogRef.close();
        this.isSpinnerVisible = false;
        this.message = "Thank you for registering services on Auxo Ads!";
        this.dialog.open(DialogDynamicComponent,
          {
            width: '450px',
            data: { message: this.message }
          });

      }
      else {
        this.message = "Something went wrong";
        this.dialog.open(DialogDynamicComponent,
          {
            width: '450px',
            data: { message: this.message }
          });
        this.isSpinnerVisible = false;
      }

    })

  }

}

// dialogue End



@Component({
  selector: 'app-publisher-vertical-header',
  templateUrl: './publisher-vertical-header.component.html',
  styleUrls: []
})

export class PublisherAppHeaderComponent implements OnDestroy, OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  animal = '';
  name = '';
  public showInvnotify = true;
  public errorMsg;
  public acc_name = localStorage.getItem('acc_name');
  public useremail = localStorage.getItem('useremail');
  child_net_code = localStorage.getItem('child_net_code');
  childnetmenu: boolean = false;
  this_month: string;
  previous_month: string;
  message: string;
  role_id = localStorage.getItem('role_id');
  roleMenu: boolean = false;
  rolePublisher: boolean = false;
  roleNetwork: boolean = false;
  roleExeMenu: boolean = false;
  roleEditiorMenu: boolean = false;
  paymentDetails:boolean=true;
  bankDetails:boolean=true;
  //demo account condition
  notificationDemo: boolean = false;
  // This is for Notifications
  notificationCount:number=0;
 // This is for Notifications  
  notifications: Object[];
  categoryColors={1:'round-primary',2:'round-info',3:'round-warning',4:'round-success',5:'round-danger'};
  categoryIcon={1:'fa-thin fa-g',2:'fa-thin fa-h',3:'fa-thin fa-b',4:'fa-thin fa-k',5:'fa-thin fa-a'};

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  }

  public languages: any[] = [{
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  },
  {
    language: 'Español',
    code: 'es',
    icon: 'es'
  },
  {
    language: 'Français',
    code: 'fr',
    icon: 'fr'
  },
  {
    language: 'German',
    code: 'de',
    icon: 'de'
  }]

  // dialogue start

  constructor(private translate: TranslateService, public dialog: MatDialog, private headerService: HeaderService,public router: Router,private notificationService: NotificationService) {
    translate.setDefaultLang('en');
  }
  ngOnDestroy(){
    //this.notificationService.notificationSubject.unsubscribe();
  }
  ngOnInit(): void {
   
    this.setNotificationCount();
    this.getRecentNotifications();

    this.notificationService.notificationSubject.subscribe(action=>{
      if(action == 'read'){
        this.notificationCount=0;
      }
    });
     
    //demo condition
    if(this.child_net_code == "demo_9999999"){
      this.notificationDemo = true;
    }

    //role condtion
    if (this.role_id == '3') {
      this.rolePublisher = true;
    }
    if (this.role_id == '6') {
      this.roleNetwork = true;
    }

    // if (this.role_id == '12') {
    //   this.roleMenu = true;

    // }
    // if (this.role_id == '13') {
    //   this.roleExeMenu = true;

    // }
    if (this.role_id == '12') {
      this.roleMenu = true;
      if(this.child_net_code == "22783525167"){
       
        this.paymentDetails=false;
      }

    }
    if (this.role_id == '13') {
      this.roleExeMenu = true;
      if(this.child_net_code == "22783525167"){
       
        this.paymentDetails=false;
      }

    }
    if (this.role_id == '14') {
      this.roleEditiorMenu = true;
    }
   
    if (this.role_id == '12' || this.role_id=='13') {
      this.roleMenu = true;
      if(this.child_net_code == "22869266182"){
       
        this.paymentDetails=false;
        this.bankDetails=false;
      }

    }

    //header revenue

    this.headerService.getHeaderRev(localStorage.getItem('uniq_id'), localStorage.getItem('child_net_code'), localStorage.getItem('pub_acc_name'), localStorage.getItem('pub_acc_new_name'), localStorage.getItem('ads_id')).subscribe((response) => {

      if (response['status_code'] == '200') {
        this.this_month = response['data']['this_month'];
        this.previous_month = response['data']['last_month'];

      } else {
        this.message = "Something went wrong";
        this.dialog.open(DialogDynamicComponent,
          {
            width: '450px',
            data: { message: this.message }
          });
      }
    })


    this.headerService.getInvoicenotify(localStorage.getItem('uniq_id'))
      .subscribe(dataResult => {
        if (dataResult['status_code'] == '201') {
          this.showInvnotify = true;
        } else if (dataResult['status_code'] == '200') {
          this.showInvnotify = true;
        } else {
          this.showInvnotify = false;
        }
      },
        error => this.errorMsg = error);

    setInterval(() => { this.myFunc() }, 180000);
  }

  myFunc() {
    this.headerService.getInvoicenotify(localStorage.getItem('uniq_id'))
      .subscribe(dataResult => {
        if (dataResult['status_code'] == '201') {
          this.showInvnotify = true;
        } else if (dataResult['status_code'] == '200') {
          this.showInvnotify = true;
        } else {
          this.showInvnotify = false;
        }
      },
        error => this.errorMsg = error);
  }

  setNotificationCount(){
    this.notificationService.getNotificationsCount(localStorage.getItem('uniq_id'))
    .subscribe(response=>{
      if(response && response.status_code=='200'){
        this.notificationCount=response['response_data'];
      }
      
    });
  }
  getRecentNotifications(){
    this.notificationService.getRecentNotifications(localStorage.getItem('uniq_id'))
    .subscribe(response=>{
      if(response && response.status_code=='200'){
        this.notifications=response['response_data'];
      }
      
    });
  }
  readNotification(notification_id:string){
    // cuurent ntification object
    let n={};
    for(let i=0;i<this.notifications.length;i++){
      if(this.notifications[i]['id']==notification_id){
        n=this.notifications[i];
        break;
      }
    }
    if(n['read_flag'] == 0){
      n['read_flag']=1;
      // if(this.notificationCount>0){
      //   this.notificationCount--;
      // }
      this.notificationService.readNotification(localStorage.getItem('uniq_id'),notification_id)
      .subscribe(response=>{
        if(response && response.status_code=='200'){
          this.setNotificationCount();
          this.getRecentNotifications();
        }
      });
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PublisherAppHeaderDialogComponent, {
      width: '420px',
      data: { name: this.name, animal: this.animal }
    });

    
  }

  // dialogue start

  changeLanguage(lang: any) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
  }


  showDatePicker(){
    switch(this.router.url){
      case '/keyinsights/audience-overview':
      case '/keyinsights/content-performance':
      case '/keyinsights/trafic-analysis':
      case '/tools/publishertools-ads-txt-automation':
      case '/tools/publisher-tools-api-doc':
        return false;
      default:
        return true;
    }
  }


  
}

<div mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</div>
<h2 class="font-bold"  mat-dialog-title><strong>Inventory Settings</strong></h2>
<form #inventoryForm="ngForm" (ngSubmit)="invenSubmit(inventoryForm)">
<mat-dialog-content class="pb-3">
<div fxLayout="row wrap">
  <!-- 1 column start -->
<!--   <div fxFlex="100" fxFlex.gt-md="90" fxFlex.gt-xs="90">
    <h4 class="m-t-0">Use Dynamic Allocation
      <sup class="text-muted font-14"><br>Read More</sup>
    </h4> 
  </div>

  <div fxFlex="100" fxFlex.gt-md="10" fxFlex.gt-xs="10" class="m-t-5 m-t-mobile">
    <mat-slide-toggle [(ngModel)]="isChecked1">{{isChecked1}}</mat-slide-toggle>
  </div> -->

  <!-- 2 column start -->
  <div fxFlex="100" fxFlex.gt-md="90" fxFlex.gt-xs="90">
    <h4 class="m-t-0">Activate Premium Demand
      <sup class="text-muted font-14"><br>Read More</sup>
    </h4> 
  </div>
  <div fxFlex="100" fxFlex.gt-md="10" fxFlex.gt-xs="10" class="m-t-5 m-t-mobile">
    <mat-slide-toggle [(ngModel)]="isChecked2" name="premium">{{isChecked2}}</mat-slide-toggle>
  </div>

  <!-- 3 column start -->
  <div fxFlex="100" fxFlex.gt-md="90" fxFlex.gt-xs="90">
    <h4 class="m-t-0">Maximize Fill Rates
      <sup class="text-muted font-14"><br>Read More</sup>
    </h4> 
  </div>
  <div fxFlex="100" fxFlex.gt-md="10" fxFlex.gt-xs="10" class="m-t-5 m-t-mobile">
    <mat-slide-toggle [(ngModel)]="isChecked3" name="fill">{{isChecked3}}</mat-slide-toggle>
  </div>

  <!-- 4 column start -->
  <div fxFlex="100" fxFlex.gt-md="90" fxFlex.gt-xs="90">
   <h4 class="m-t-0">Auxo Ads Analytics + CDP
      <sup class="text-muted font-14"><br>Read More</sup>
    </h4> 
  </div>
  <div fxFlex="100" fxFlex.gt-md="10" fxFlex.gt-xs="10" class="m-t-5 m-t-mobile">
    <mat-slide-toggle [(ngModel)]="isChecked4" name="cdp">{{isChecked4}}</mat-slide-toggle>
  </div>

  <!-- 5 column start -->
  <!-- <div fxFlex="100" fxFlex.gt-md="100" fxFlex.gt-xs="100">
    <h6 class="m-t-0 text-muted font-14">Get Auxo Ads NewsLetter</h6> 
   </div> -->
   <div fxFlex="100" fxFlex.gt-md="90" fxFlex.gt-xs="90">
    <h4 class="m-t-0">Get Auxo Ads Newsletter
       <sup class="text-muted font-14"><br>Read More</sup>
     </h4> 
   </div>
   <div fxFlex="100" fxFlex.gt-md="10" fxFlex.gt-xs="10" class="m-t-5 m-t-mobile">
     <mat-slide-toggle [(ngModel)]="isChecked5" name="letter">{{isChecked5}}</mat-slide-toggle>
   </div>
</div>
</mat-dialog-content>

<div mat-dialog-actions align="center" class="m-b-0">
  <!-- <button class="bg-danger text-white" mat-button [mat-dialog-close]="data.animal" tabindex="2">Submit</button> -->
  <button class="bg-danger text-white" mat-button  tabindex="2">Submit</button>
  <!-- <button class="bg-danger text-white" mat-button (click)="onNoClick()" tabindex="-1">No Thanks</button> -->
</div>
</form>
<!--loader-->
                
<div class="bg-opacity" *ngIf="isSpinnerVisible">
  <div class="parent">
  <div class="spin1"></div>
  </div>
</div>
 <!--loaderend--> 
<div mat-icon-button class="close-button m-t-5" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</div>
<h2 class="font-bold m-l-10" mat-dialog-title><strong>Edit</strong></h2>
<!---Loader Start-->
<div class="bg-opacity" *ngIf="isSpinnerVisible">
  <div class="parent">
    <div class="spin1"></div>
  </div>
</div>
<!---Loader End-->
<form class="m-t-0  p-b-0" #f="ngForm" (submit)="onSubmit(f)"  >
  <mat-dialog-content>
    <div fxLayout="row wrap" style="margin-top: -10px!important"> 
      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
       
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>Activity Status</mat-label>
          <mat-select matInput name="comment" required  #fontSize value="16px" [(ngModel)]="comments" (selectionChange)="onFormChange();onCommentsChange()">
            <mat-option value="Completed">Completed</mat-option>
            <mat-option value="In Process">In Process</mat-option>
            <mat-option value="Not Active">Not Active</mat-option>
            <mat-option value="Not Applicable">Not Applicable</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10"  *ngIf="comments == 'In Process'" >
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>Update</mat-label>
          <mat-select matInput #fontSize value="16px" [(value)]="details"  (selectionChange)="onFormChange()">
            <mat-option value="Publisher Tech Issue">Publisher Tech Issue</mat-option>
            <mat-option value="Unable to reach Pub">Unable to reach Pub</mat-option>
            <mat-option value="Expected in coming Week">Expected in coming Week</mat-option>
            <mat-option value="Others">Others</mat-option>
          </mat-select>       
        </mat-form-field>
      </div>
       
      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10" *ngIf="comments == 'Not Active'" >
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>Update</mat-label>
          <mat-select matInput  #fontSize value="16px" [(value)]="details" (selectionChange)="onFormChange()">
            <mat-option value="Publisher not interested">Publisher not interested</mat-option>
            <mat-option value="Has tried without results">Has tried without results</mat-option>
            <mat-option value="Publisher managing in-house">Publisher managing in-house</mat-option>
            <mat-option value="Others">Others</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10" *ngIf="comments == 'Not Applicable'" >
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>Update</mat-label>
          <mat-select matInput  #fontSize value="16px" [(value)]="details" (selectionChange)="onFormChange()">
            <mat-option value="Publisher not interested">Publisher not interested</mat-option>
            <mat-option value="Publisher has tried with another partner">Publisher has tried with another partner</mat-option>
            <mat-option value="Publisher managing in-house">Publisher managing in-house</mat-option>
            <mat-option value="Others">Others</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">   
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>Remarks</mat-label>
          <textarea matInput placeholder="Remarks" [(ngModel)]="remarks" name="remarks" (change)="onFormChange()" >
      </textarea>
        </mat-form-field>
      </div>
     
      <div fxFlex.gt-sm="100" fxFlex="100" class="p-10">
        <div class="p-r-0 m-b-10 m-t-5 text-center">
          <button mat-stroked-button color="" id="" class=" bg-danger text-white" disabled>Submit</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
 
</form>
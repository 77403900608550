import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../global/app-url';
interface myData {
    data: any,
    status_code: string
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
   
    constructor(private http: HttpClient) {
    }
    
    notificationSubject = new Subject<string>();

     getAllNotifications(uniq_id:string) {
        var t = localStorage.getItem('access_token');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/user/notification/get_publisher_recent_notification.php', {
           uniq_id,
           "view_listing" : "all"
        }, httpOptions)

    }
   
    readAllNotification(uniq_id:string) {
       
        var t = localStorage.getItem('access_token');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/user/notification/update_notification_read_flag.php', {
           uniq_id,
           "view" : "all"
        }, httpOptions);
    }

    getNotificationsCount(uniq_id:string) {
        var t = localStorage.getItem('access_token');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/user/notification/publisher_notification_counter.php', {
           uniq_id,
        }, httpOptions)

    }
    
    getRecentNotifications(uniq_id:string) {
        var t = localStorage.getItem('access_token');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/user/notification/get_publisher_recent_notification.php', {
           uniq_id,
        }, httpOptions)

    }

    readNotification(uniq_id:string,notification_id:string) {
        var t = localStorage.getItem('access_token');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/user/notification/update_notification_read_flag.php', {
           uniq_id,
           notification_id
        }, httpOptions)

    }

}
<!-- daterangepicker start -->
<!-- <div class="m-r-50 m-t-5" fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" id="daterangepickeralignment">
    <app-daterangepicker></app-daterangepicker>
  </div> -->
  <!-- daterangepicker End --> 

<!-- First month start -->
<!-- <button mat-icon-button class="m-r-50 btnhidethismonth top1" style="color: #000!important;"> -->
    <!-- <small>This Month</small> -->
    <!-- <h6 style="margin-top: -17px;" class="font-18">${{this_month}}</h6> -->
<!-- </button> -->
<!-- First month End -->

<!-- last month start -->
<!-- <button   mat-icon-button class="m-r-25 btnhidethismonth top1" style="color: #000!important;"> -->
    <!-- <small>Last Month</small> -->
    <!-- <h6 style="margin-top: -17px;" class="font-18">${{previous_month}}</h6> -->
<!-- </button> -->
<!-- last month End -->

<!-- <button  mat-icon-button class="m-r-5 m-t-3 btnhidethismonth" style="color: #000!important;"> -->
<!-- <h6>|</h6> -->
<!-- </button> -->

<!-- Payment start -->
<!-- <button routerLink="./invoice-list" mat-icon-button class="m-r-5 m-t-3 mobilehide1 iconmobilenav" *ngIf="!childnetmenu" style="color: #000!important;"> -->
    <!-- <mat-icon>account_balance</mat-icon> -->
    <!-- <div *ngIf="showInvnotify" class="notify"> -->
        <!-- <span class="heartbit"></span> -->
        <!-- <span class="point"></span> -->
    <!-- </div> -->
<!-- </button> -->
<!-- payment end -->


<!-- Notification start -->
<button [matMenuTriggerFor]="notification" mat-icon-button class="m-r-5 m-t-3 mobilehide1 iconmobilenav"
    style="color: #000!important;">
    <mat-icon matBadge="15" matBadgeColor="warn">notifications</mat-icon>
    <!-- <div class="notify">
        <span class="heartbit"></span>
        <span class="point"></span>
    </div> -->
</button>
<mat-menu #notification="matMenu" class="mymegamenu toptriangle">
    <div class="mailbox">
        <ul>
            <!-- <li style="margin-top: -6px!important;">
                <div class="drop-title title-highlight text-center font-24">Notification</div>
            </li> -->

            <div class="drop-title-new" fxLayout="row wrap">
                <div fxFlex.gt-sm="60" fxFlex.gt-lg="60" fxFlex.gt-md="60" fxFlex.gt-xs="60" fxFlex="60">
                    <li>
                        <div class="title-highlight text-left" style="font-size: 18px!important;">Notification Hub</div>
                    </li>
                </div>

                <div fxFlex.gt-sm="40" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="40" fxFlex="40">
                    <li class="text-right" style="margin-top: -8px!important;">
                        <button class="font-12 p-0" mat-raised-button  matBadge="15" matBadgePosition="before"
                            matBadgeColor="warn">15 New</button>
                    </li>
                </div>
            </div>

            <li>
                <div class="message-center">
                    <perfect-scrollbar [config]="config">
                        <a class="notify-unread" href="../finance/notification-hub" *ngFor="let notification of notifications">
                            <div class="round {{notification.round}}">
                                <i class="{{notification.icon}}" style="font-size: 27px!important; margin-top: 8px!important;"></i>
                            </div>
                            <div class="mail-content">
                                <h5 class="title-highlight">{{notification.title}}</h5>
                                <span class="mail-desc para-highlight">{{notification.subject}}</span>
                                <span class="time notify-time">{{notification.time}}</span>
                            </div>
                        </a>
                    </perfect-scrollbar>
                </div>

                <div class="p-r-0 m-b-10 m-t-15 text-center" style="position: sticky; bottom: 0;">
                    <button [routerLink]="['../finance/notification-hub']" mat-stroked-button color="" class="bg-danger text-white" style="width: 50%;">View all Notifications</button>
                </div>
            </li>
        </ul>
    </div>
</mat-menu>
<!-- =================notification end==================== -->


<!-------------------email start ----------->

<!-- <button [matMenuTriggerFor]="message" mat-icon-button class="m-r-5">
    <mat-icon>email</mat-icon>
    <div class="notify">
        <span class="heartbit"></span>
        <span class="point"></span>
    </div>
</button>
<mat-menu #message="matMenu" class="mymessage">
    <div class="mailbox">
        <ul>
            <li>
                <div class="drop-title">You have 4 new Mymessages</div>
            </li>
            <li>
                <div class="message-center">
                    <perfect-scrollbar [config]="config">
                        <a href="#" *ngFor="let mymessage of mymessages">
                            <div class="user-img">
                                <img src="{{mymessage.useravatar}}" alt="user" class="img-circle" width="40">
                                <span class="profile-status {{mymessage.status}} pull-right"></span>
                            </div>
                            <div class="mail-content">
                                <h5>{{mymessage.from}}</h5>
                                <span class="mail-desc">{{mymessage.subject}}</span>
                                <span class="time">{{mymessage.time}}</span>
                            </div>
                        </a>
                    </perfect-scrollbar>
                </div>
            </li>
        </ul>
    </div>
</mat-menu> -->
<!-- =========================email end===================================== -->

<!-- =======================Manage Users======================================= -->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5 m-t-3 mobilehide1 iconmobilenav" style="color: #000!important;">
<!-- <img src="assets/images/users/1.jpg" alt="user" class="profile-pic"> </button> -->
<mat-icon style="font-size: 34px;">account_circle</mat-icon>
<mat-menu #profile="matMenu" class="mymegamenu">
    <div class="text-dark p-l-20 p-r-20"> 
        <!-- bg-light-info -->
        <div class="d-flex align-items-center">
            <img src="assets/images/users/default.png" width="75" class="img-rounded" />
            <div class="m-l-10">
                <h5 style="color: #455a64;" class="m-0 font-medium">{{acc_name}}</h5>
                <span class="text-muted font-14" style="color: #67757c;">{{useremail}}</span>
            </div>
        </div>
    </div>

    

    <!-- <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon> Disable notifications
    </button> -->

    <button mat-menu-item>
        <mat-icon style="color: #455a64;" routerLink="./logout" id="usedhover">exit_to_app</mat-icon>
        <a style="color: #455a64;" routerLink="./logout" id="usedhover">Log Out</a>
    </button>
</mat-menu>
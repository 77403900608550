import { Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogDynamicComponent } from '../../dialog-dynamic/dialog-dynamic.component';
import { ServiceinvoiceService } from '../serviceinvoice.service';
import { MatTableDataSource } from '@angular/material/table';
import { Location } from '@angular/common';

declare var $: any;
const MaxFileSize =2048000;
// Popup start
@Component({
    selector: 'app-dialog-upload-invoice-list',
    templateUrl: 'dialog-upload-invoice-list.html',
    styles:[
          '.mat-table.cdk-table{width:100% !important;}'
    ]
})

export class DialogUploadInvoiceListComponent  {
    isSpinnerVisible: boolean;
  
  
    login_uniq_id: string;
 
    invoice_month:string;
    message:any;
   
    child_net_code:string;
    isBusy=false;
    isEdit=false;
    isChanged=false;
   
    isSubmitted=false;
    invoiceList: MatTableDataSource<Element>;
    // 'chk', 'totalCost',  sandeep  bhai used this 17 line
    displayedColumns: string[] = ['name', 'correct_value', 'msg'];
    
    
    constructor(
        public dialogRef: MatDialogRef<DialogUploadInvoiceListComponent>,
        @Inject(MAT_DIALOG_DATA) public model: any, private location: Location, public _UploadService:ServiceinvoiceService,private router: Router,public dialog: MatDialog
    ) { 
      //this.invoiceList=new MatTableDataSource<Element>([{details:'Test',correctDetails:'This is a Test',status:'Completed'}]);
    //  console.log(model);
      this.invoiceList=new MatTableDataSource<Element>(model);

         
    }
  formChangeSubscription:Subscription;
  ngOnInit(): void {

   this.isSubmitted=false;
  }

    onNoClick(): void {
        this.dialogRef.close(false);
    }
    onFormChange(){
      this.isChanged=true;
    }
    backPage(){
      this.location.back();
    }
   
    onSubmit(f:NgForm){  
   
    }    
     
}

export interface Element {
  name:string;
  correct_value:string;
  msg:string;
}
// popup end




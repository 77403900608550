<div mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</div>

<!-- <mat-dialog-content class="pb-3"> -->
<div fxLayout="row wrap">
  <div fxFlex="100" fxFlex.gt-md="100" fxFlex.gt-xs="100">
    <h4 class="m-l-10 m-r-10 popuplineheight" align="center">
      {{data.message}}
    </h4>

    <h4 class="m-l-10 m-r-10 popuplineheight font-22" align="center" style="margin-top: -5px!important;">
      {{data.message1}}
    </h4>
  </div>
</div>
<!-- </mat-dialog-content> -->
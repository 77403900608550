<div mat-icon-button class="close-button m-t-5" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</div>
<h2 class="font-bold m-l-10" mat-dialog-title><strong>Add Ads.txt</strong></h2>
<form class="m-t-0  p-b-0" [formGroup]="adstxtform" (ngSubmit)="adstxtSubmit()">
  <mat-dialog-content class="pb-3">
    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>Ad Network Domain</mat-label>
          <input matInput required id="" name="net_domain" placeholder="Enter Ad Network Domain" [formControl]="adstxtform.controls['net_domain']">
        </mat-form-field>
        <small *ngIf="adstxtform.controls['net_domain'].errors?.pattern"  class="text-danger support-text logintrp-css spacing-adjust m-b-20">Please provide a valid domain address</small>
       
      </div>

      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>Ad Network Account ID</mat-label>
          <input matInput required id="" name="acc_id" placeholder="Enter Ad Network Account Id" [formControl]="adstxtform.controls['acc_id']">
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0 m-b-f5">
          <mat-label>Account Type</mat-label>
          <mat-select #fontSize value="16px" [formControl]="adstxtform.controls['acc_type']">
            <mat-option value="DIRECT">Direct</mat-option>
            <mat-option value="RESELLER">Reseller</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="50" fxFlex="100" class="p-10">
        <mat-form-field appearance="outline" class="p-r-0">
          <mat-label>Certification Authority ID</mat-label>
          <input matInput id="" name="" placeholder="Enter Certification Authority ID" [formControl]="adstxtform.controls['authority_id']">
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="100" fxFlex="100" class="p-10">
        <div class="p-r-0 m-b-10 m-t-5 text-center">
          <button mat-stroked-button color="" id="" class=" bg-danger text-white">Submit</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</form>
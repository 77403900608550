import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { NotificationsHubComponent } from './notification-hub.component';
import { AppSettings } from '../../global/app-url';
interface myData {
    data: any,
    status_code: string
}


@Injectable({
    providedIn: 'root'
})
export class NotificationHubService {
   

    constructor(private http: HttpClient) {

    }
    upsertNotification(uniq_id:string,notification_type:string,notification_type_flag:string){
        var t = localStorage.getItem('access_token');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/user/notification/publisher_notification_master_add.php', {
          uniq_id,
          notification_type,
          notification_type_flag
        }, httpOptions)
    
    }
    getNotificationData(uniq_id:string) {
        var t = localStorage.getItem('access_token');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/user/notification/get_publisher_notification_master.php', {
           uniq_id
        }, httpOptions)

    }
    
    getNotificationServiceData(uniq_id:string) {
        var t = localStorage.getItem('access_token');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/user/notification/get_notification_category_list.php', {
           uniq_id
        }, httpOptions)

    }
    updateNotification(uniq_id:string,id:string,sub_category_notification_flag:string){
        var t = localStorage.getItem('access_token');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/user/notification/publisher_notification_sub_category_master_update.php', {
          uniq_id,
          id,
          sub_category_notification_flag
        }, httpOptions)
    
    }
    sendOtp(uniq_id:string,mobile:string,operation_type:string) {
        var t = localStorage.getItem('access_token');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/user/notification/send_otp.php', {
           uniq_id,
           mobile,
           operation_type
        }, httpOptions)

    }
    
    verifyOtp(uniq_id:string,mobile:string,otp:any) {
        var t = localStorage.getItem('access_token');
        var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: headers_object
        };
        return this.http.post<myData>(AppSettings.API_STARTPOINT+'/assets/api/admin/user/notification/verified_pub_mobile.php', {
           uniq_id,
           mobile,
           otp
        }, httpOptions)

    }

}
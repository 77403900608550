<header class="bg-primary text-white">
   <!-- <div id="particles-js"></div> -->
   <!--- class(wow animated) data-wow-delay="0.1s" --->
   <div id="home"></div>
   <nav class="navbar osahan-navbar navbar-expand-lg navbar-dark fadeInDown" id="mainNav">
      <div class="container">
         <a class="navbar-brand js-scroll-trigger menu-btn1" href="#home">
          <img class="img-fluid" src="assets/registerlp/img/new/safe-logo1.png" alt="" height="70px" width="140px">
        </a>
         <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
         <span class="navbar-toggler-icon"></span>
         </button> 
         <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ml-auto navbar-font-bold">
               <li class="nav-item">
                  <a class="nav-link js-scroll-trigger" href="#home">Home</a>
               </li>
               <li class="nav-item">
                  <a class="nav-link js-scroll-trigger" href="#features">Platform</a>
               </li>
               <li class="nav-item">
                  <a class="nav-link js-scroll-trigger" href="#services">Services</a>
               </li>
               
               <li class="nav-item">
                  <a class="nav-link js-scroll-trigger" href="#testimonials">Testimonials</a>
               </li>
               
               <li class="nav-item">
                  <a class="nav-link js-scroll-trigger" href="https://blog.auxoads.com/">Blog</a>
               </li>
               
               <li class="nav-item">
                  <a class="nav-link js-scroll-trigger" href="#newsletter">Newsletter</a>
               </li>
               
               
                <li class="nav-item">
                  <a class="js-scroll-trigger menu-btn" href="#trial">Sign Up</a>
               </li>
              
               <li class="nav-item">
                  <a [routerLink]="['/authentication/login']" class="js-scroll-trigger menu-btn">Login</a>
                  <!-- <a class="js-scroll-trigger menu-btn" href="/authentication/login-new">Login</a> -->
               </li>
            </ul>
         </div>
      </div>
   </nav>
 
   <section class="banner-block pb-0 info-banner">
      <div class="container">
          <div class="row">
              <div class="col-md-6 col-md-6 text-left fadeInUp animated box bounce">
                  <img class="img-fluid mb-1 wow fadeInUp animated" src="assets/registerlp/img/new/main-image.png" alt="" style="position: relative; top: -25px!important">
              </div>
              <div class="col-md-6 col-md-6 text-center">
                  <h1 class="text-white mt-5 font-weight"> 
                  <div class="waviy">
                  <span style="--i:1">A</span>
                  <span style="--i:2">n</span>
                  <span style="--i:3">a</span>
                  <span style="--i:4">l</span>
                  <span style="--i:5">y</span>
                  <span style="--i:6">z</span>
                  <span style="--i:7">e</span>
                  </div>

                    <div class="waviy">
                  <span style="--i:1">P</span>
                  <span style="--i:2">R</span>
                  <span style="--i:3">E</span>
                  <span style="--i:4">D</span>
                  <span style="--i:5">I</span>
                  <span style="--i:6">C</span>
                  <span style="--i:7">T</span>
                  </div>

                     <div class="waviy">
                  <span style="--i:1">M</span>
                  <span style="--i:2">O</span>
                  <span style="--i:3">N</span>
                  <span style="--i:4">E</span>
                  <span style="--i:5">T</span>
                  <span style="--i:6">I</span>
                  <span style="--i:7">Z</span>
                  <span style="--i:7">E</span>
                  </div>
<!-- <span>Analyze.</span> -->
<!-- <span>Predict.</span> <span>Monetize.</span> -->
                  </h1>
                  <p class="lead mb-5 text-white">A unified hub to manage your entire monetization stack, ad operations and user data analytics</p>
                  <p class="mb-0"><a href="#liveDemoModal" data-toggle="modal" class="btn btn-light btn-lg">LIVE DEMO</a> &nbsp; <a href="#trial" class="btn btn-light btn-lg">Register Now</a></p>
              </div>
          </div>
      </div>
  </section>
   <div class="effectiv">
      <img class="svg" src="assets/registerlp/img/bg2.svg" alt="" />
   </div>
</header>

<!-- Live demo modal start -->
<div class="modal fade" id="liveDemoModal" style="overflow: scroll;">
   <div class="modal-dialog modal-lg" style="margin-top: 8%;">
      <div class="modal-content">
         <div class="modal-body">
            <div mat-icon-button class="close-button m-r-10" #closebutton data-toggle="modal" data-target="#liveDemoModal">
               <mat-icon class="close-icon" color="warn">close</mat-icon>
           </div>
            <!-- <span class="close" #closebutton data-toggle="modal" data-target="#liveDemoModal">&#10005;</span> -->
            <h5 class="text-center live-demo-heading">Request Here for a Live Demo</h5>
            <!--loader-->
            <div class="bg-opacity" *ngIf="isSpinnerVisiblelivedemo">
               <div class="parent parent-landingpage">
                  <div class="spin1"></div>
               </div>
            </div>
            <!--loaderend-->
            <div class="row">
               <div class="col-lg-6">
                  <div class="demo-image">
                     <img src="assets/registerlp/img/demo.png" alt="demo-image" class="img-responsive" style="width: 100%;">
                  </div>
               </div>
               <div class="col-lg-6">
                  <div class="trial-block demo-from">
                     <form [formGroup]="liveDemoform" (ngSubmit)="liveDemoSubmit()">
                        <div fxLayout="row wrap">
                            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                              <mat-form-field appearance="outline" class="p-r-0">
                                  <mat-label>Name</mat-label>
                                    <input matInput placeholder="Name" [formControl]="liveDemoform.controls['userName']">
                                </mat-form-field>
                                <small *ngIf="liveDemoform.controls['userName'].errors?.whitespace && liveDemoform.controls['userName'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Please enter valid name</small>
                                <small *ngIf="liveDemoform.controls['userName'].errors?.minlength"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth"> Name must be 3 characters</small>
                                <small *ngIf="liveDemoform.controls['userName'].errors?.pattern"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Name must be characters</small>
                            </div>
    
                             <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                               <mat-form-field appearance="outline" class="p-r-0">
                                   <mat-label>Email</mat-label>
                                     <input type="email" matInput placeholder="Email" [formControl]="liveDemoform.controls['livedemoemail']">
                                 </mat-form-field>
                                 <small *ngIf="liveDemoform.controls['livedemoemail'].errors && liveDemoform.controls['livedemoemail'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Please enter valid email</small>
                             </div>
   
                             <div fxFlex.gt-sm="100" fxFlex="100">
                              <mat-form-field appearance="outline" class="p-r-0">
                                <mat-label>Your Message Here</mat-label>
                                 <textarea matInput placeholder="" [formControl]="liveDemoform.controls['textname']"></textarea>
                              </mat-form-field>
                              <small *ngIf="liveDemoform.controls['textname'].errors?.whitespace && liveDemoform.controls['textname'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Please enter valid message</small>
                              <small *ngIf="liveDemoform.controls['textname'].errors?.minlength && liveDemoform.controls['userName'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Message must be 3 characters</small>
                            </div>
                    
                            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                              <button style="padding: 0px 39px!important;" type="submit" id="reg_submit" name="" class="btn btn-primary btn-lg btn-block m-t-10">SUBMIT</button>
                           </div>
                        </div>
                    </form>
                     
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<!-- Header end -->

<!-- Our Platform start -->
<section class="features-block ff" id="features" >
  <div class="container">
     <div class="section-title text-center wow animated zoomIn pb-0">
        <h2>Our Platform</h2>
        <span class="section-title-line "></span>
     </div>
     <div class="row">
        <div class="col-md-4 wow animated fadeInUp ppagem" data-wow-delay="0.1s" id="gam">
           <div class="features-item text-center">
              <img class="img-fluid mb-4" src="assets/registerlp/img/icons/icon2.png" alt="">
              <h5 class="mb-4">Ads Management</h5>
              <p>End-to-end ad inventory management across direct, programmatic and ad network demand</p>
           </div>
        </div>
         <div class="col-md-4 wow animated fadeInUp ppagem" data-wow-delay="0.5s" id="d-ad">
           <div class="features-item text-center">
              <img class="img-fluid mb-4" src="assets/registerlp/img/icons/icon5.png" alt="">
              <h5 class="mb-4">Programmatic Demand</h5>
              <p>Earn More Via Programmatic & Premium Direct Demand</p>
           </div>
        </div>
        <div class="col-md-4 wow animated fadeInUp ppagem" data-wow-delay="0.3s" id="report">
           <div class="features-item text-center">
              <img class="img-fluid mb-4" src="assets/registerlp/img/icons/icon3.png" alt="">
              <h5 class="mb-4">Unified Reporting</h5>
              <p>Live and Consolidated Data and Reports at your finger-tips </p>
           </div>
        </div>
     </div>
     
     <div class="row mt-5">
        <div class="col-md-4 wow animated fadeInUp ppagem" data-wow-delay="0.4s" id="ed">
           <div class="features-item text-center">
              <img class="img-fluid mb-4" src="assets/registerlp/img/icons/icon1.png" alt="">
              <h5 class="mb-4">Editorial Dashboard</h5>
              <p>Analyze and Empower your most critical asset – your Content! </p>
           </div>
        </div>
          <div class="col-md-4 wow animated fadeInUp ppagem" data-wow-delay="0.2s" id="da">
           <div class="features-item text-center">
              <img class="img-fluid mb-4" src="assets/registerlp/img/icons/icon4.png" alt="">
              <h5 class="mb-4">Deep Analytics</h5>
              <p>Get Crucial Insights and make Effective Editorial & Revenue decisions</p>
           </div>
        </div>
        <div class="col-md-4 wow animated fadeInUp ppagem" data-wow-delay="0.5s" id="afm">
           <div class="features-item text-center">
              <img class="img-fluid mb-4" src="assets/registerlp/img/icons/icon6.png" alt="">
              <h5 class="mb-4">Auxoads Log In & Payments </h5>
              <p>Secure and Automated platform ensures auxoads log-in and timely payments </p>
           </div>
        </div>
        
     </div>
     <!-- <div class="clearfix" id="trial" ></div> -->
  </div>
</section>


<!-- counter start -->
<section class="why-us-block bg-primary" id="why-us">
   <div class="container-fluid">
       <div class="section-title text-center">
           <h2 class="text-white">Our Numbers</h2>
           <!-- <span class="section-title-line section-title-line line-white"></span> -->
       </div>
       <div class="row">
           <div class="col-lg-3 col-sm-6">
               <div class="why-us-item text-center text-white m-t-20">
                  <img src="assets/registerlp/img/icons/domain.png">
                   <!-- <h1 class="mb-0 mt-3 text-white timer count-title count-number"data-to="4000" data-speed="20000"></h1> -->
                   <h1 class="mb-0 mt-3 text-white counterfont">{{domainCount}}</h1>
                   <p class="mb-0 text-white">DOMAINS</p>
               </div>
           </div>
           <div class="col-lg-3 col-sm-6">
               <div class="why-us-item text-center text-white m-t-20">
                  <img src="assets/registerlp/img/icons/impression.png">
                      <h1 class="mb-0 mt-3 text-white counterfont">{{impressionCount}}</h1>
                      <p class="mb-0 text-white">IMPRESSIONS SERVED</p>
                    <!-- <h1 class="mb-0 mt-3 text-white timer count-title count-number"data-to="7500000000" data-speed="3400189"></h1> -->
               </div>
           </div>
           <div class="col-lg-3 col-sm-6">
               <div class="why-us-item text-center text-white m-t-20">
                  <img src="assets/registerlp/img/icons/page-views.png">
                   <!-- <h1 class="mb-0 mt-3 text-white timer count-title count-number"data-to="3000000000" data-speed="3420189"></h1> -->
                   <h1 class="mb-0 mt-3 text-white counterfont">{{pageviewCount}}</h1>
                   <p class="mb-0 text-white">PAGEVIEWS</p>
               </div>
           </div>
           <div class="col-lg-3 col-sm-6">
               <div class="why-us-item text-center text-white m-t-20">
                  <img src="assets/registerlp/img/icons/demand.png">
                    <!-- <h1 class="mb-0 mt-3 text-white timer count-title count-number"data-to="50" data-speed="20000"></h1> -->
                    <h1 class="mb-0 mt-3 text-white counterfont">{{demandCount}}</h1>
                   <p class="mb-0 text-white">DEMAND PARTNERS</p>
               </div>
           </div>
       </div>
       <div class="clearfix" id="trial" ></div>
   </div>
</section>

<!-- Sign up start -->
<section class="trial-block" style="background:#f2f5fa;padding:100px 0px 50px 0px;">
   <div class="container">
       <div class="section-title text-center wow animated zoomIn">
           <h2>Sign Up</h2>
           <p>Please complete the form below and we will get in touch ASAP!</p>
           <span class="section-title-line section-title-line"></span>
           <div class="alert-success"></div>
             <div class="error text-danger"></div>
       </div>

       <div class="row">
           <div class="col-md-6 col-sm-12"><img src="assets/registerlp/img/new/Register-user.png" class="img-responsive laptopsize"> 
      </div>

           <div class="col-md-6">
                   <form [formGroup]="form" (ngSubmit)="onSubmit()">
                       <div fxLayout="row wrap">
                           <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="pform5">
                             <mat-form-field appearance="outline"  class="p-r-0">
                                 <mat-label>Email</mat-label>
                                   <input matInput placeholder="Email" type="text"  [formControl]="form.controls['email']">
                               </mat-form-field>
                               <small *ngIf="form.controls['email'].errors && form.controls['email'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Must be a valid email address</small>
                               <small *ngIf="emailCheck"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">E-Mail address already exists, please check details!</small>  
                           </div>
   
                           <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100" class="pform5">
                              <mat-form-field appearance="outline" class="p-r-0">
                                 <mat-label>Password</mat-label>
                                   <input matInput type="password" placeholder="Password" [formControl]="form.controls['password']" minlength="8">
                                   
                               </mat-form-field>
                               <small *ngIf="form.controls['password'].errors?.minlength && form.controls['password'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Password must be at least 8 characters</small>
                               <small *ngIf="form.controls['password'].errors?.cannotContainSpace && form.controls['password'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Password can not contain space</small>
                           </div>

                           <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100" class="pform5">
                              <mat-form-field appearance="outline" class="p-r-0">
                                  <mat-label>Confirm Password</mat-label>
                                    <input matInput type="password" placeholder="Confirm Password" [formControl]="form.controls['confirmPassword']" minlength="8">
                                </mat-form-field>
                                <small *ngIf="form.controls['confirmPassword'].errors?.equalTo && form.controls['confirmPassword'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Passwords do not match</small>
                                <small *ngIf="form.controls['confirmPassword'].errors?.minlength && form.controls['confirmPassword'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Password must be at least 8 characters</small>
                            </div>

                            <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100" class="pform5">
                              <mat-form-field appearance="outline" class="p-r-0">
                                  <mat-label>First Name</mat-label>
                                    <input matInput type="text" placeholder="First Name" [formControl]="form.controls['fname']">
                                </mat-form-field>
                                <small *ngIf="form.controls['fname'].errors?.whitespace && form.controls['fname'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Please enter valid first name</small>
                                   
                                <small *ngIf="form.controls['fname'].errors?.minlength"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth"> First name must be 3 characters</small>
                                <small *ngIf="form.controls['fname'].errors?.pattern"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">First name must be characters</small>
                            </div>

                            <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100" class="pform5">
                              <mat-form-field appearance="outline" class="p-r-0">
                                  <mat-label>Last Name</mat-label>
                                    <input matInput type="text" placeholder="Last Name" [formControl]="form.controls['lname']">
                                </mat-form-field>
                                <small *ngIf="form.controls['lname'].errors?.pattern"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Last name must be characters</small>
                            </div>
                            <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100" class="pform5">
                              <mat-form-field appearance="outline" class="p-r-0">
                                  <mat-label>Select Website/App</mat-label>
                                  <mat-select #fontSize (selectionChange)="onBusnChange($event)" value="16px" placeholder="Website/App" [formControl]="form.controls['business_type']">
                                       <mat-option value="App">App</mat-option>
                                       <mat-option value="Website">Website</mat-option>
                                   </mat-select> 
                              </mat-form-field>
                            </div>
                            <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100" class="pform5">
                              <mat-form-field appearance="outline">
                                  <mat-label>Domain URL</mat-label>
                                    <input type="text" matInput placeholder="Domain URL" [formControl]="form.controls['domain']">
                                </mat-form-field>
                                <small *ngIf="form.controls['domain'].errors?.pattern"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Please provide a valid domain address</small>
                                <small *ngIf="domainCheck"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">{{errorDomain}}</small>
                            </div>
                            
                            <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100" class="pform5">
                              <mat-form-field appearance="outline" class="p-r-0">
                                  <mat-label>Select Country Code</mat-label>
                                     <!-- <input matInput placeholder="Country Code" [formControl]="form.controls['country']"> -->
                                  <mat-select #fontSize value="16px" placeholder="Country Code" [formControl]="form.controls['country']">
                                       <mat-option *ngFor="let country of countrydata; let i = index" [value]="countrydata[i].id">{{countrydata[i].nicename}} +{{countrydata[i].phonecode}}</mat-option>
                                      <!--  <mat-option value="Arunachal Pradesh">Arunachal Pradesh</mat-option> -->
                                   </mat-select> 
                              </mat-form-field>
                            </div>

                            <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100" class="pform5">
                              <mat-form-field appearance="outline" class="p-r-0">
                                  <mat-label>Mobile Number</mat-label>
                                    <input type="text" maxlength=12 matInput placeholder="Mobile Number" [formControl]="form.controls['mobile']" >
                                </mat-form-field>
                                <small *ngIf="form.controls['mobile'].errors?.pattern"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Please enter valid mobile number</small>
                                <small *ngIf="form.controls['mobile'].errors?.minlength"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth"> Mobile number must be 8 numeric  characters</small>
                                <small *ngIf="form.controls['mobile'].errors?.maxlength"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth"> Mobile number must be 12 numeric characters</small>
                            </div>

                            <div class="form-group m-l-10">
                              <div class="checkbox m-b-5">
                                 <div class="chiller_cb" >
                                    <input id="myCheckbox" type="checkbox" [formControl]="form.controls['savetc']">
                                    <label for="myCheckbox">I accept the <a id="doclink1" href="#tncModal" data-toggle="modal" class="tnc">terms &amp; conditions</a></label>
                                    <span></span>
                                 </div>      
                              </div>
                            
                              <span class="m-b-5 font-12" *ngIf="submitted && form.controls['savetc'].errors" style="color:#e94265;">please accept the terms & conditions</span>
                              <!-- <span class="m-b-5 font-16" *ngIf="submitted && form.valid"  style="color:#3dd080;">{{message}}</span> -->
                              <span class="m-b-5 font-16" *ngIf="submitted && form.valid"  style="color:#e94265;">{{error}}</span>
                           </div>
                            
                           <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                              <button style="margin-top:10px;" type="submit" id="" name="" class="btn btn-primary btn-lg btn-block" >SIGN UP</button>
                           </div>
                       </div>
                   </form>
      <!-- html form start -->
               <!-- <form name="reg_form1" id="regContactForm" method="post" action="" novalidate autocomplete="off">
                  <div class="control-group form-group">
                       <div class="controls">
                           <input type="email" name="pub_emial" placeholder="Enter Your Email" class="form-control" id="pub_emial" required readonly onfocus="this.removeAttribute('readonly');"/>
                       </div>
                   </div>

                         <input type="text" id="reg_uname_res" name="reg_uname_res" value="" hidden />

                   <div class="row">
                       <div class="control-group form-group col-md-6">
                           <div class="controls">
                               <input type="password" placeholder="Enter Your Password" class="form-control" id="pub_pass" name="pub_pass" required readonly onfocus="this.removeAttribute('readonly');" onblur="Validatepassword()" />
                           </div>
                       </div>
                       <div class="control-group form-group col-md-6">
                           <div class="controls">
                               <input type="password" placeholder="Confirm Password" class="form-control" id="pub_pass_conf" name="pub_pass_conf" required onblur="Validatepassword()" readonly onfocus="this.removeAttribute('readonly');">
                           </div>
                       </div>
                   </div>

                   <div class="row">
                        <div class="control-group form-group col-md-6">
                           <div class="controls">
                              <input type="text" placeholder="First Name" class="form-control" id="reg_first_name" name="reg_first_name" required data-validation-required-message="Please enter your first name.">
                           </div>
                       </div>

                       <div class="control-group form-group col-md-6">
                           <div class="controls">
                               <input type="text" placeholder=" Last Name" class="form-control" id="reg_last_name" name="reg_last_name" >
                           </div>
                       </div>
                   </div>

                   <div class="control-group form-group">
                       <div class="controls">
                           <input type="text" placeholder="Domain URL" class="form-control" id="reg_website" name="reg_website" required data-validation-required-message="Please enter your primary domain url.">
                           <div id="uweb_response" name="uweb_response" class="response"></div>
                       </div>
                   </div>

                   <div class="row">
                   <div class="control-group form-group col-md-5">
                   <div class="controls">
                   <select name="country_id" id="country_id" class="form-control">
                   <option value="">Select Country Code</option>
                   <option value="abc">abc</option>
                   </select>
                   </div>
                   </div>

                   <div class="control-group form-group col-md-7">
                   <div class="controls">
                   <input type="tel" placeholder="Mobile Number (Global)" class="form-control" id="phone" name="reg_cont_no" required data-validation-required-message="Please enter your mobile number.">
                   </div>
                   </div>   
                   </div>

               <div class="form-group">
                  <div class="checkbox">
                     <div class="chiller_cb">
                        <input id="myCheckbox" type="checkbox" checked="">
                        <label for="myCheckbox">I accept the <a id="doclink1" href="#tncModal" data-toggle="modal" class="tnc">terms &amp; conditions</a></label>
                        <span></span>
                     </div>
                  </div>
               </div>
              <button style="margin-top:25px;" type="submit" id="reg_submit" name="" class="btn btn-primary btn-lg btn-block">SIGN UP</button>
               </form> -->
     <!-- html form End -->
           </div>
       </div>
       <div class="clearfix" id="services"></div>
       <div class="clearfix" id="cdp"></div>
   </div>
</section>

<!-- SignUp Modal start -->
<div class="modal fade" id="tncModal">
   <div class="modal-dialog modal-lg" style="margin-top: 8%;">
      <div class="modal-content">
      <div class="modal-header">
         <h4 class="modal-title">Terms & Conditions</h4>
            <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
            <div mat-icon-button class="close-button m-r-10 m-t-20" data-dismiss="modal">
               <mat-icon class="close-icon" color="warn">close</mat-icon>
         </div>
      </div>
      
         <div class="modal-body" style="max-height:400px; overflow-y:scroll;">
            <h5 class="text-center"></h5>
            <div class="col-md-10 col-md-offset-1" id="dv_terms">
            <ol>
            <li>
               <span class="h5">Definitions</span>
               <ol>
               <li>"Affiliate" shall mean,in relation to each of the Parties; (a) any parent/holding company of that Party; (b) any corporate body of which that Party directly or indirectly has control; or (c) any corporate body directly or indirectly controlled by the same person/entity or group of persons/entities as that Party.</li>
               <li>“Confidential Information” means any information of the disclosing party which is, or should reasonably be understood to be, confidential or proprietary to the disclosing party or its client, including, but not limited to, information disclosed between parties, either directly or indirectly, in writing, drawing, orally, or electronically.</li>
               <li>“Data Protection and Privacy Requirements” means each party’s respective obligations under applicable data protection and privacy laws, regulations, and industry self-regulatory rules, codes and guidelines.</li>
               <li>“Demand Partner” means the Company’s media buying clients, including but not limited to demand side platforms (DSP’s), ad exchanges, ad agencies, trading desks and ad networks.</li>
               <li>“Invalid Activities” means prohibited activities including but not limited to plagiarized or copied content, incorrect ad placements, spam traffic, any policy or compliance violations, data breach or hacking activities; placement of any ad in a manner that attempts to interfere with, abuse or gain an unfair advantage in inventory monetisation. </li>
               <li>“Marks” means a party’s names, logos, trademarks, service marks, domain names, trade names, logos, other creative or product materials, or identifying insignia, or symbols and abbreviations thereof.</li>
               <li>“Net Earnings” represents revenue earned by the Publisher based on Publisher Inventory monetized via the Company Services as determined by the Company.</li>
               <li>“Prohibited Content” means all content displayed on any Publisher Property that is forbidden pursuant to the supply services including invalid contents, spams, contents which violate any law etc.</li>
               <li>“Publisher Data” means the data it provides to or makes available to the Company under this Agreement.</li>
               <li>“Publisher Inventory” means the online advertising inventory or ad slots made available by the Publisher on its properties and third-party publisher properties managed by the Publisher.</li>
               <li>“Company Data” means data derived from or related to the Company Services, including all data collected by the Company or its Demand Partners in connection with the Company Services.</li>
               <li>“Company Services” means services provided under this Agreement.</li>
               <li>"Auxo Ads" refers to the technology platform of the Company used to provide its services including monetization, ad management, programmatic demand, customer data platform, among other offerings to publishers. </li>
            </ol>
            </li>
               <p>&nbsp;</p>
            <li>
               <span class="h5">Company Services:</span>
            <ol>
               <li>Provision of Services: The Company will use commercially reasonable efforts to provide the Publisher with the applicable Services of the Company using its Auxo Ads Platform.</li>
               <li>License: During the term of this Agreement, the Company shall grant the Publisher a non-exclusive, non-sub-licensable right to access to the Company Services for the purposes of (a) displaying advertisements on Publisher Properties through the Auxo Ads platform, and (b) receiving reporting data made available via Auxo Ads for the Publisher’s internal business purposes. The Publisher hereby grants Auxo Ads platform the right to serve monetization, ad management, programmatic demand, customer data platform, among other offerings to publishers.</li>
               <li>Multiple Demand Channels: The Company shall have the right to use multiple demand partners in order to provide services under this Agreement.</li>
               <li>Account Credentials: In connection with applicable Services of the Company, the Publisher may set or be issued account credentials, including, but not limited to, username, publisher name, email and password. The Publisher shall be responsible for maintaining, protecting, and securing all account credentials and will not share account credentials with any unauthorized persons. In the event of an actual or suspected breach or unauthorized use of account credentials, the Publisher shall promptly provide written notice to the Company of any such incident with reasonable supporting details and documentation. The Publisher shall solely be responsible for all account activities related to account credentials, regardless of whether such activity(ies) is/are authorized or unauthorized, and the Company disclaims any and all liabilities related to or arising out of such account activity(ies).</li>
               </ol>
            </li>
            <p>&nbsp;</p>
            <li>
               <span class="h5">Commercials and Payment Terms:</span>
            <ol>
               <li>Net Earnings; Payments and Reporting:
                  <ol>
                     <li>Invoicing shall be carried out on a monthly billing cycle, subject to no invalid activity observed against the Publisher domains.</li>
                     <li>Monthly net earnings shall be subject to deductions against invalid activities.</li>
                     <li>The Company shall pay the Publisher the invoice amount within seven (7) days from the date of receipt of valid invoice.</li>
                     <li>Payments shall be in US Dollars. Any bank charges applicable on transfer, domestic or foreign, shall be borne by the Publisher.</li>
                     <li>In order to release payments, the Publisher shall provide the Company,all necessary documents associated therewith and its complete bank account details.</li>
                     <li>The Company shall provide the Publisher a monthly statement which shall include the net earnings. If the Publisher does not raise any issues in writing about the monthly statement within thirty (30) days of receipt, the Publisher will forfeit its right to do so. The Publisher acknowledges that all numbers displayed in any other reporting apart from the monthly statement made available by the Company are estimates and do not represent valid numbers for billing purposes.</li>
                  </ol>
               </li>
               <li>
                  Taxes: The Publisher will pay all taxes (including excise, sales, use, consumption, value-added or withholding taxes), customs or import duties, or any other levies, tariffs, duties or governmental fees that are due or payable in connection with this Agreement (“Taxes”), with the exception of taxes on the Company’s net income. Each party agrees to co-operate in good faith with respect to reasonable requests from the other party regarding tax-related forms, documentation or other information relating to this Agreement that may be necessary or appropriate.
                  </li>
               </ol>
            </li>
            <li>
               <span class="h5">Term and Termination:</span>
            <ol>
               <li>Term: The initial term of this Agreement shall be for a period of twelve (12) months and shall begin from the date of acceptance of the Company (the “Initial Term”), unless earlier terminated by either Party through serving a prior written notice of thirty (30) days upon the other Party. Provided that at the end of the Initial Term and renewal term thereafter (together with the Initial Term, the “Term”), the term of this Agreement shall automatically renew till such time (“Renewal Term”) unless and until either party gives the other party a prior written notice of at least thirty (30) days for termination of this Agreement.</li>
               <li>Termination for Cause: A party shall have the right to terminate this Agreement if the other party breaches any material term or condition of this Agreement and fails to cure such breach within ten (10) days after receipt of written notice of such breach. Termination of this Agreement by either party for breach will be a non-exclusive remedy for breach and will be without prejudice to any other right or remedy of such party.</li>   
               <li>Effects of Termination: In the event of expiry of the Initial Term or termination of this Agreement, the Publisher shall immediately cease using the Company Services, and each party shall destroy or return to the other party all copies in any form of the other party’s Confidential Information. The provisions of sub-clause 4.3 (Effects of Termination) and Clause 7 (Privacy and Data Use) of this Agreement will survive termination of this Agreement.</li>
            </ol>
            </li>
            <li>
               <span class="h5">Warranties and Disclaimer:</span>
            <ol>
               <li>5.1	Mutual Warranties: Each party represents and warrants to the other party that:
                  <ol>
                     <li>the person executing this Agreement is duly authorized and has the legal capacity to execute and deliver this Agreement</li>
                     <li>it is duly organized and existing and is in good standing under the laws of its jurisdiction of formation or incorporation;</li>
                     <li>it has all necessary rights and authority to enter into, execute and perform its obligations under this Agreement; and</li>
                     <li>the execution of this Agreement and the performance of its respective obligations hereunder do not and will not violate any agreement to which such party is a party or by which it is otherwise bound.</li>
                  </ol>
               </li>
               <li>Company Warranties: The Company represents and warrants to the Publisher that the Company will not violate any applicable law, rule or regulation in connection with the performance of the Company Services hereunder. The Company shall not be liable to loss, change or damage of any details like, bank account details etc. provided by the Publisher.</li>
               <li>Publisher Warranties: The Publisher represents and warrants to the Company that:
                  <ol>
                     <li>Each Publisher's inventory that shall be monetised via the Company Services shall: (i) neither violate any applicable laws or regulations, nor contain Prohibited Contents, and (ii) properly categorize the inventory type for the site ID of the Publisher Property in accordance with the Company’s then current guidelines;</li>
                     <li>The Publisher shall, knowingly or unknowingly, not generate invalid traffic, spam, such contents which violate any law via any means. If such traffic is generated by the Publisher, the Company shall have the right to take necessary action, following global standards that protect advertisers and are in line with Auxo Ads guidelines to product and intellectual properties. Further, the Company shall not in any way be liable to pay for such contents:</li>
                     <li>It will not, and will not permit any third party to, directly or indirectly without written approval of the Company:(i) access, launch or activate Auxo Ads Technology through or from, or otherwise incorporate Auxo Ads in any software application, website or other means other than the Publisher Properties, (ii) transfer, sell, lease, syndicate or otherwise sublicense the Auxo Ads platform, (iii) generate queries, or impressions of or clicks on ads, through any automated, deceptive, fraudulent or other invalid means, (iv) encourage or require end users or any other persons, either with or without their knowledge, to click on the advertisements displayed through the Company services through offering methods that are manipulative, deceptive, malicious or fraudulent, or (v) pass any data to the Company that could be used to personally identify any person without obtaining such person’s consent to pass such data to the Company (including, without limitation, any personally identifiable information or personal data passed via custom key-values or other parameters);</li>
                     <li>It has and will maintain throughout the term, all rights, authorizations and licenses (including without limitation any copyright, trademark, patent, publicity or other rights) that are required with respect to the Publisher Properties and to permit the Company to provide the Company Services to the Publisher as contemplated under this Agreement; </li>
                     <li>The Publisher shall provide the Companyinventory as per specifications and upto the mark as determined by the Company. In case the same are not upto the mark, the Company shall have the right to deduct charges of such inventory from the financials to be paid to the Publisher; 
                        </li>
                        <li>If the Publisher is found involved in any invalid activities, they shall not be entitled to any payment from the Company, and the Company shall not be liable to pay anyamount to the Publisher under the provisions of Clause 3 of this Agreement; and</li>
                        <li>The Publisher further represents and warrants that if,due to any reasons whatsoever on part of the Publisher,any deduction is made from the Company’s Account by athird party, the Company shall have the right to recover the same from the Publisherunder the legal remedies available to the Company including equitable relief.</li>
                     </ol>
               </li>
               <li>Disclaimer: Except as expressly provided for in this Agreement and to the maximum extent permitted by applicable law, THE PARTIES (AND THEIR AFFILIATES) EXCLUDE ANY WARRANTY OR GUARANTEE OF ANY KIND, WHETHER IMPLIED, STAUTORY, OR OTHERWISE, AND DISCLAIM AND EXCLUDE WITHOUT LIMITATION ALL CONDITIONS, TERMS, REPRESENTATIONS AND UNDERTAKINGS, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE, AND NON-INFRINGEMENT.</li>
            </ol>
            </li>
            <li>
               <span class="h5">Intellectual Property Rights:</span>
            <ol>
               <li>prietary Ownership:
                  <ol>
                     <li>Each Party retains all rights, title in and to all its Intellectual Property rights including without limitation goodwill, copyrights and moral rights.</li>
                     <li>The Company is the sole and exclusive owner of all property rights or interests, legal or beneficial, tangible or intangible, of any sort and kind, in any shape or form, in connection with Auxo Ads technology, including but not limited to, intellectual and industrial property rights, know-how, trade secrets, developments, and any data of any sort and kind, oral, written or otherwise, and the Publisher shall not claim, breach, violate or infringe in any way whatsoever these rights.</li>
                  </ol>
               </li>
               <li>Use of Marks: The Publisher grants to the Company a royalty-free, non-exclusive, worldwide right and license to use Publisher’s Marks related to the Publisher properties that use the Company Services, for commercial and corporate use, including but not limited to sales, marketing, advertising, publicity, corporate securities and securities filings. Except as expressly provided for in this Agreement, neither party shall use the other party’s Marks without the other party’s prior written consent.</li>
            </ol>
            </li>
            <li>
               <span class="h5">Privacy and Data Use:</span>
            <ol>
               <li>Privacy Policy:The Publisher shall abide by a privacy policy consistent with applicable laws and post such privacy policy on each web page of each Publisher Property where data gathering for interest-based advertising occurs.Such privacy policy shall include:
                  <ol>
                     <li>clear and conspicuous notice of Publisher’s use of methods to collect, use and share user data, including methods of the Company and its Demand Partners;</li>   
                     <li>a statement that data may be collected for interest-based or contexual-based advertising purposes on the Publisher Property;</li>
                     <li>disclosure of categories of Demand Partners or other third parties with which data will be shared;</li>
                     <li>a clear description of the types of data, including personally identifiable information, precise location data, sensitive data, sensor data or personal directory data, that are collected for interest-based or contexual advertising purposes on the Publisher Property;</li>
                     <li>an explanation of the purposes for which data is collected by, or will be transferred to, third parties;</li>
                     <li>a disclosure that statistical identification or other non-cookie technologies (such as eTags and web or browser cache) may be used by third parties on the Publisher Property, including the fact that browser settings that block cookies may have no effect on such technologies;</li>
                     <li>a disclosure of the fact that data collected from a particular browser or device may be used with another computer or device that is linked to the browser or device on which such data was collected; and</li>
                     <li>a clear and conspicuous link to, or description of how to access, an opt-out mechanism for interest-based advertising, cross-app advertising, statistical identification, and cross-device targeting.</li>
                  </ol>
               </li>
               <li>Privacy Compliance Requirements:
                  <ol>
                     <li>Each party agrees that it shall comply with its respective obligations under applicable Data Protection and Privacy Requirements including GDPR (General Data Protection Regulations).</li>
                     <li>The Publisher agrees that it shall be responsible and liable for providing notice and obtaining any required clear and conspicuous user consent on behalf of itself, the Company and all applicable Demand Partners with respect to (a) passing personal information, precise location data, user device identification or any sensitive information of its users to the Company and Demand Partners, (b) collecting and using statistical identification of its users or other non-cookie technologies (such as eTags and web or browser cache), (c) collecting and using information across web browsers and devices, and (d) first- and third-party cookies, including without limitation, ensuring users are clearly informed about third-party cookies and promptly notifying the Company in the event a user opts out of having third-party cookies dropped. In the event, Data Protection and Privacy Requirements require user consent to pass personal information to the Company and Demand Partners, the Publisher shall not pass such personal information to the Company or Demand Partners without obtaining the requisite consent. The Company will cooperate with the Publisher in complying with such requirements.</li>
                     <li>The Publisher shall not include or activate any Publisher Property on any of the Company Services if such Publisher Property is directed at children under the age of fourteen (14) years, and the Publisher shall flag within the Company Services or inform the Company in writing prior to launching any of such Publisher Properties on any of the Company Services. The Publisher shall not pass to the Company or its Demand Partners any personal information of children under fourteen (14) years of age.</li>
                     <li>If any Publisher Property is a mobile application, then the Publisher must clearly and conspicuously post notice, or a link to such notice, in any store or on any website or otherwise where the mobile applicable may be acquired that contains: (a) a statement of the fact that data may be collected for cross-app advertising; (b) a clear and conspicuous description of types of data, including any personally identifiable information, sensitive data, sensor data or personal, precise location data, or personal directory data, that are collected for cross-app advertising purposes; (c) an explanation of how, and for what purpose, the data collected will be used or transferred to third parties; and (d) a clear and conspicuous link to or description of how to access an opt-out mechanism.  If notice cannot be provided in or around advertisements, then the Publisher should make arrangements to provide notice within the application or on the landing page of the advertisement.</li>
                  </ol>
               </li>
               <li>Data Ownership, Use and Feedback. The Company retains all right, title and interest in and to all the Company Data. The Publisher retains all right, title and interest in and to all Publisher Data. The Publisher hereby grants the Company and its Demand Partners a limited, irrevocable, royalty-free, worldwide license to collect, use and share user data that is passed by the Publisher (a) in connection with the Company Services, (b) to use such data for reporting purposes, and (c) in response to a legal demand or process.  Notwithstanding anything to the contrary in this Agreement, the Publisher grants the Company an irrevocable, royalty free, fully paid up, worldwide license to any ideas, concepts, modifications, suggestions, improvements, enhancements and information arising out of or related to Publisher’s use of the Company Services.</li>
            </ol>
            </li>
            <li style="list-style-type: none;">
               <span class="h5">Confidentiality:</span>
               Each party shall treat as proprietary and shall maintain in strict confidence all Confidential Information of the other party and shall not, without the express prior written consent of such other party, disclose such Confidential Information or use such Confidential Information other than in furtherance of its obligations hereunder. Notwithstanding the foregoing, ‘Confidential Information’ shall not include information which the receiving party can demonstrate with written documentation: (a) is known to the receiving party at the time of the disclosure; (b) has become publicly known through no wrongful act of the receiving party; (c) has rightfully been received from a third-party which the disclosing party has authorized to make such disclosures; or (d) was disclosed pursuant to a court order or similar governmental authority, provided, however, that the receiving party shall provide prompt notice of such order to the disclosing party to enable the disclosing party to act to prevent or restrict the ordered disclosure. The terms of this Agreement shall be deemed Confidential Information of the Company. The Parties hereby agree that for the purpose of business growth and services under this Agreement, required disclosure about the execution of this Agreement may be made to the statutory authority.
            </li>
            <li style="list-style-type: none;">
               <span class="h5">Assignment:</span>
               The rights and obligations of either party under the Agreement cannot be assigned without prior written consent of the other party, however suchconsent shall not be unreasonably withheld by the consenting party.Provided that either party may assign this Agreement without such consent to its affiliate or in connection with a change of control or sale of substantially all of its assets, subject to the assigning party providing the other party prior written intimation of such assignment. Any attempted assignment without consent where consent is required shall be void. This Agreement shall not confer any rights or remedies upon any person or third party other than the Publisher, the Company, and their respective permitted successors and assigns
               </li>
            <li>
               <span class="h5">Indemnification:</span>
               <ol>
                  <li>Company’s Indemnity Obligations: The Company agrees to indemnify, defend and hold harmless Publisher and its officers, directors, shareholders, corporate affiliates, agents, successors and assigns (collectively, the “Publisher Indemnified Parties”) from and against any third-party claim, suit or proceeding (“Claim”) against the Publisher Indemnified Parties arising out of, related to, or alleging: </li>
                  <li>(i) infringement of any intellectual property right of a third-party by the Company Services; or (ii) any violation by the Company of applicable privacy laws.</li> 
                  <li>Publisher’s Indemnity Obligations: The Publisher agrees to indemnify, defend and hold harmless the Company and its officers, directors, shareholders, corporate affiliates, agents, successors and assigns (collectively, the “Company Indemnified Parties”) from and against any Claim against the Company Indemnified Parties (including legal costs and reasonable attorneys’ fees) arising out of, related to, or alleging: (i) infringement of any intellectual property right of a third-party by Publisher or its clients; (ii) any violation by Publisher of applicable privacy laws or Publisher’s breach of Sub-Clauses 7.1 (Privacy Policy) or 7.2 (Compliance with Privacy); (iii) any violation of any Publisher Property of applicable laws; or (iv) the inclusion of any Prohibited Content on any Publisher Property.</li>
                  <li>General Indemnity Provisions: The party seeking indemnification will promptly notify the other party of the claim and cooperate with the other party in defending the claim. The indemnifying party has full control and authority over the defense, except that any settlement requiring the party seeking indemnification to admit liability or to pay any money will require that party’s prior written consent, such consent not to be unreasonably withheld or delayed. The other party may join in the defense with its own counsel at its own expense. THE INDEMNITIES IN SUBSECTIONS 10.1 and 10.2 ARE THE ONLY REMEDY UNDER THIS AGREEMENT FOR VIOLATION OF A THIRD PARTY’S INTELLECTUAL PROPERTY RIGHTS.</li>
               </ol>
            </li>
            <li style="list-style-type: none;">
               <span class="h5">Limitation of Liability</span>
               
                  Except with respect to either Party's confidentiality, indemnification and payment obligations, Publisher's breach of Clause 7 (Privacy and Data Use), or fraud and wilful misconduct, in no event shall either party's aggregate liability under this Agreement exceed the Publisher fee due under this Agreement in the three-month period preceding the claim. Except with respect to Publisher's breach of Clause 7 (Privacy and Data Use), in no event shall either party be liable for any indirect, incidental, consequential, punitive or special damages (including lost profits; loss of use or lost data) of the other party, even if such party has been advised of the possibility of such damages, nor shall the Company be liable for the accts or omissions of Publisher's demand partners or clients, Publisher's activities with respect to other publishers who use the Company services, or any third party service providers of the Company or Publisher.
               
            </li>
            <li>
               <span class="h5">Dispute Resolution</span>
               All disputes arising in connection with or arising out this Agreement (“Dispute”) shall be amicably settled by negotiation between the Parties or their authorised representatives. In the event of failure of the Parties to reach any settlement through negotiations within a period of thirty (30) days from the date of commencement of such negotiations or upon a failure of the Parties to resort to negotiation, such Dispute, shall be referred to a Sole Arbitrator appointed by the Company. The arbitration award shall be final and binding upon both the Parties.
              
            </li>
            <li>
               <span class="h5">Miscellaneous</span>
               <ol>
                  <li>Entire Agreement: This Agreement constitutes the entire agreement between the parties, and supersedes all prior agreements or communications, relating to the subject matter hereof.</li>   
                  <li>Modification: This Agreement shall not be modified except through a written document between the parties; provided, however, that the Company may amend or modify any of the terms and conditions in the Agreement upon at least seven (07) days’ prior written notice to the Publisher. If any such changes notified by the Company are to commercial terms in the Agreement and they are not acceptable to the Publisher, the Publisher must notify the Company in writing of its objection thereto prior to the expiration of such seven (07) days’ notice period. If such written notification is not made by the Publisher during the applicable seven (07) days period, continued use of the Company Services by the Publisher, will constitute its binding acceptance of the applicable changes to the commercial terms. For the avoidance of doubt, the Publisher shall not object to the noticed changes to non-commercial terms.</li>   
                  <li>Waiver: The failure of either party to enforce strict performance by the other party of any provision of the Agreement or to exercise any right hereunder or thereunder shall not be construed as a waiver of that party’s right. In the event that any provision of this Agreement is held invalid by a court with jurisdiction over the parties, such provision shall be deemed to be restated to reflect as nearly as possible the original intentions of the parties in accordance with applicable law, and the remainder of the Agreement shall remain in full force and effect. </li>   
                  <li>Relationship: The Parties are eachindependent contractors, and nothing in this Agreement is intended to create a partnership, joint venture or agency relationship between them. Subject to the foregoing, each party’s rights and obligations shall inure to the benefit of their respective successors and permitted assigns.</li>   
                  <li>Non-Solicitation: The Parties hereby agree that any Party shall not directly or indirectly solicit or offer employment to or hire any employees or representatives of the other Party during the period of this Agreement including any renewal and for twelve (12) months after the expiry or termination of this Agreement.</li>   
                  <li>Notices: All notices and other communications given or made under this Agreement shall be in English, in writing and shall be deemed effectively given upon the earlier of actual receipt on: (a) personal delivery to the party to be notified, (b) when sent, if sent by electronic mail or facsimile during normal business hours of the recipient, and if not sent during normal business hours, then on the recipient’s next business day, (c) five (5) days after having been sent by registered or certified mail, return receipt requested, postage prepaid, or (d) one (1) business day after deposit with a nationally recognized overnight courier, freight prepaid, with written verification of receipt, to the recipient's address specified in this Agreement or such other address as specified by the parties in writing.</li>   
                  <li>Force Majeure: Neither Party will be liable for inadequate performance to the extent caused by a condition (for example, natural disaster, act of God or war or terrorism, riot, labour condition, governmental action etc.) that was beyond the Party’s reasonable control, provided that the Party subject to such events shall take necessary steps to mitigate the effect of such events.</li>   
               </ol>
            </li>
            </ol>
           </div>
         </div>
     <div class="modal-footer">
</div>
      </div>
   </div>
</div>

<!-- Our Services start -->
<section class="features-mid-block" style="padding: 60px 0px 30px 0px" id=""> 
   <div class="container">
   <div class="section-title text-center wow  zoomIn animated" style="visibility: visible; animation-name: zoomIn;">
         <h2>Our Services</h2>
         <span class="section-title-line section-title-line"></span>
      </div>

       <div class="row">
        <div class="col-md-6 wow animated fadeInLeft" data-wow-delay="0.5s">
            <div class="features-mid-right text-left">
               <img class="img-fluid" src="assets/registerlp/img/new/customer-data.png" alt="">
            </div>
         </div>
         <div class="col-md-6 wow animated fadeInRight" data-wow-delay="0.10s" >
            <div class="features-mid-left pt-4">
               <h2 class="mb-2"  style="font-size:25px;">Customer Data Platform (CDP)</h2>
               <span class="section-title-line mb-4"></span>
               <p class="mb-5 ">Our CDP solution enables publishers to get control of their data and audiences. Publishers are able to use this solution to deliver targeted campaigns to their advertising partners. This helps drive higher monetization programmatically.</p>
               <!-- <a href="#trial" class="btn btn-primary">Create Your Account</a> -->
            </div>
         </div>
      </div>
   </div>
   <div class="clearfix" id="ao"></div>
</section>

<section class="features-mid-block pt-0" style="padding: 60px 0px 60px 0px" id="">
   <div class="container">
      <div class="row" >
          <div class="col-md-6 fadeInLeft" data-wow-delay="0.5s" >
            <div class="features-mid-left pt-4">
               <h2 class="mb-2"  style="font-size:25px;">Ad Operation Management</h2>
               <span class="section-title-line mb-4"></span>
               <p class="mb-5 ">If Ad server management and optimization is your business concern then let our experts provide you the best in class support for your campaign management and ad serving.  As a Google Partner we  are equipped  with world class GAM360 management capabilities thus helping you optimize your ROI.</p>
               <!-- <a href="#trial" class="btn btn-primary">Create Your Account</a> -->
            </div>
         </div>

           <div class="col-md-6 wow animated fadeInRight" data-wow-delay="0.10s">
            <div class="features-mid-right text-right">
               <img class="img-fluid" src="assets/registerlp/img/new/management.png" alt="">
            </div>
         </div>
      </div>
   </div>
   <div class="clearfix" id="seo"></div>
</section>

 <section class="features-mid-block pt-0" style="padding: 60px 0px 60px 0px" id="">
   <div class="container">
       <div class="row" >
         <div class="col-md-6 wow animated fadeInLeft" data-wow-delay="0.5s">
            <div class="features-mid-right text-left">
               <img class="img-fluid" src="assets/registerlp/img/new/seo-new.png" alt="">
            </div>
         </div>
         <div class="col-md-6 wow animated fadeInRight" data-wow-delay="0.10s" >
            <div class="features-mid-left pt-4">
               <h2 class="mb-2" style="font-size:25px;">Traffic Optimization</h2>
               <span class="section-title-line mb-4"></span>
               <p class="mb-5 ">Our SEO support includes analysis of keywords, page speeds, meta-data to help you optimize accessibility of your website. Drive organic growth by deploying best practices that ensure long-term impact across search traffic.</p>
               <!-- <a href="#trial" class="btn btn-primary">Create Your Account</a> -->
            </div>
         </div>
      </div>
   </div>
   <div class="clearfix" id="cs"></div>
</section>

<section class="features-mid-block pt-0" style="padding: 60px 0px 60px 0px" id="">
   <div class="container">
      <div class="row" >
         <div class="col-md-6 wow animated fadeInRight" data-wow-delay="0.10s">
            <div class="features-mid-left pt-4">
               <h2 class="mb-2"  style="font-size:25px;">Content Strategy</h2>
               <span class="section-title-line mb-4"></span>
               <p class="mb-5 ">Our 30+ years experience in building Content and Communities helps publishers create their future Growth Strategy. Discover the best processes, tips and resources for your content strategy and engage your readers more.</p>
               <!-- <a href="#trial" class="btn btn-primary">Create Your Account</a> -->
            </div>
         </div>

          <div class="col-md-6 wow animated fadeInLeft" data-wow-delay="0.5s" >
            <div class="features-mid-right text-left">
               <img class="img-fluid" src="assets/registerlp/img/new/ads-management.png" alt="">
            </div>
         </div>
      </div>
   </div>
</section>

<!-- Screenshots start -->
<section class="screens-block bg-primary" id="screen" style="padding: 60px 0px 60px 0px">
   <div class="screens-container-fluid">
   <div class="section-title text-center wow animated zoomIn">
      <h2 class="text-white">Screenshots</h2>
      <!-- <span class="section-title-line section-title-line line-white"></span> -->
   </div>
   <div class="screens-row text-center" style="filter: blur(0.7px);">
   <div class="screens owl-carousel owl-theme">
      <div class="item">
         <img src="assets/registerlp/img/screenshot/new1.png" class="img-responsive">
      </div>
      <div class="item">
         <img src="assets/registerlp/img/screenshot/new2.png" class="img-responsive">
      </div>
      <div class="item">
         <img src="assets/registerlp/img/screenshot/new3.png" class="img-responsive">
      </div>
      <div class="item">
         <img src="assets/registerlp/img/screenshot/new4.png" class="img-responsive">
      </div>
      <div class="item">
         <img src="assets/registerlp/img/screenshot/new5.png" class="img-responsive">
      </div>
      <div class="item">
         <img src="assets/registerlp/img/screenshot/new6.png" class="img-responsive">
      </div>
      <div class="item">
         <img src="assets/registerlp/img/screenshot/new7.png" class="img-responsive">
      </div>
      <div class="item">
         <img src="assets/registerlp/img/screenshot/new8.png" class="img-responsive">
      </div>
   </div>
   </div></div>
</section>

<!-- Watch Our Video start -->
   <!--    <section class="video-block pb-0" id="video">
        <div class="container">
            <div class="section-title text-center">
                <h2>Watch Our Video</h2>
                <span class="section-title-line"></span>
            </div>
            <div class="row">
                <div class="col-md-11 mx-auto">
                    <div class="video-item text-center">
                        <p><a data-toggle="modal" data-target="#video-model" class="play-btn" href="#"><i class="fa fa-play"></i></a></p>
                        <img class="img-fluid mt-5" src="landing_page/img/app-shot.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="video-model" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Watch Our Video</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                       <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <iframe width="100%" height="400" src="https://www.youtube.com/embed/UIWAyPidR7g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- our happy clients -->
    <section class="testimonials-block" id="testimonials" style="padding: 100px 0px 60px 0px">
      <div class="container">
         <div class="section-title text-center wow animated zoomIn">
            <h2>Our Happy Clients</h2>
            <span class="section-title-line"></span>
         </div>
         <div class="row wow animated zoomIn" data-wow-delay="0.9s">
           
            <div class="blogs-slider owl-carousel owl-theme">
               <div class="item">
                  <div class="col-md-12 wow  fadeInUp animated" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
                     <div class="custom-card testimonials-item text-center">
                        <img class="img-fluid mb-4" src="assets/registerlp/img/test-3.png" alt="">
                        <p class="mb-4 fonttt18">CyberMedia helped us with a direct campaign of LG for a period of 10 days starting the 20th July. Cybermedia Team helped us with an increase of the fill rate from 40% to 80% above, at an improved RPM rate due to Programmatic Deals provided. While the creative size was 300x600, it has still helped us gain additional revenue for this period. They were very cooperative and helpful.</p>
                        <h6 class="mb-0 text-primary">- Sandiran Kaunder</h6>
                        <!-- <h6 class="mb-0 text-primary" style="font-size: 14px;">(CEO at Zigya.com)</h6> -->
                       <small>CEO at Zigya.com</small>
                     </div>
                  </div>
               </div>
               <div class="item">
                  <div class="col-md-12 wow  fadeInUp animated" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
                     <div class="custom-card testimonials-item text-center">
                        <img class="img-fluid mb-4" src="assets/registerlp/img/test-4.png" alt="">
                        <p class="mb-4 fonttt18">Cybermedia has a knowledgeable team well versed with the nuances of programmatic advertising. Thanks Cybermedia media team for all your help and assistance all along. Looking forward to growing the partnership.</p>
                        <h6 class="mb-0 text-primary">- Karan Sahni</h6>
                       <small>CEO JamboJar</small>
                     </div>
                  </div>
               </div>
               <div class="item">
                  <div class="col-md-12 wow  fadeInUp animated" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
                     <div class="custom-card testimonials-item text-center">
                        <img class="img-fluid mb-4" src="assets/registerlp/img/test-5.png" alt="">
                        <p class="mb-4 fonttt18">Thanks Cybermedia media team for your help and assistance all along. Looking forward to such partnership in future as well.</p>
                        <h6 class="mb-0 text-primary">- Arun Sharma</h6>
                       <small>Delivery Head at Videogrid</small>
                     </div>
                  </div>
               </div>
               <div class="item">
                  <div class="col-md-12 wow  fadeInUp animated" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
                     <div class="custom-card testimonials-item text-center">
                        <img class="img-fluid mb-4" src="assets/registerlp/img/test-6.png" alt="">
                        <p class="mb-4 fonttt18">Cybermedia Team helped me today and in the past whenever I needed it, I have seen an increase in CPM and Fill in the past as well. I am looking forward to their more efforts and coordination along with me. Thank you Team CyberMedia.</p>
                        <h6 class="mb-0 text-primary">- Harish</h6>
                       <small>Tech Follows</small>
                     </div>
                  </div>
               </div>
               <div class="item">
                  <div class="col-md-12 wow  fadeInUp animated" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
                     <div class="custom-card testimonials-item text-center">
                        <img class="img-fluid mb-4" src="assets/registerlp/img/test-7.png" alt="">
                        <p class="mb-4 fonttt18">I have been working with cybermedia for the last two years and they are providing us the best services in this industry. As a Partner with cybermedia I am getting my payment without a delay as per their said timings for the last two years. I really appreciate the services from the cyber media. Especially payment related. Automatically an invoice gets generated and makes payment without a single day delay.Thanks Cybermedia.</p>
                        <h6 class="mb-0 text-primary">- Dhananjay Pandey</h6>
                       <small>Head at Serial Gossips</small>
                     </div>
                  </div>
               </div>
               <div class="item">
                  <div class="col-md-12 wow  fadeInUp animated" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
                     <div class="custom-card testimonials-item text-center">
                        <img class="img-fluid mb-4" src="assets/registerlp/img/test-9.png" alt="">
                        <p class="mb-4 fonttt18">I want to take the time to thank you for the excellent job you have been doing for us its your your work, effort and timely support which makes our work smooth and easy. It's a pleasure working with you! Best regards, Addmind Services.</p>
                        <h6 class="mb-0 text-primary">- Addmind Service Team</h6>
                      <!--  <small>Director</small>-->
                     </div>
                  </div>
               </div>
               <div class="item">
                  <div class="col-md-12 wow  fadeInUp animated" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
                     <div class="custom-card testimonials-item text-center">
                        <img class="img-fluid mb-4" src="assets/registerlp/img/test-8.png" alt="">
                        <p class="mb-4 fonttt18">It is a very good point for us to get prompt response and fine routine work from your end so we want to appreciate it on your behalf.Looking for the same in near future.</p>
                        <h6 class="mb-0 text-primary">- Haris Ali</h6>
                       <small>Sales Manager at Mahimeta.com</small>
                     </div>
                  </div>
               </div>
               <div class="item">
                  <div class="col-md-12 wow  fadeInUp animated" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
                     <div class="custom-card testimonials-item text-center">
                        <img class="img-fluid mb-4" src="assets/registerlp/img/test1.jpg" alt="">
                        <p class="mb-4 fonttt18">Rajnikant V/s CID Jokes is India's leading Facebook page in entertainment category. When we started our website, we didn't choose any adsense partner network. After 6 months, we got associated with Cybermedia just to try their services for 2 months but our monthly adsense revenue was increased by 80% in very first month with almost same number of page views. We never knew playing with ad placement can help us increasing the adsense revenue with this much margin. All thanks to Cybermedia.</p>
                        <h6 class="mb-0 text-primary">- Rajnikant V/s CID</h6>
                      <!--  <small>Director</small>-->
                     </div>
                  </div>
               </div>
           
             
            </div>
         </div>
      </div>
   </section>

   <!-- SignUp NewsLetter start -->
   <section class="sign-up-blockdelete" id="newsletter" style="padding: 100px 0px 50px 0px">
      <div class="container">
         <div class="section-title text-center wow animated zoomIn" style="padding-bottom: 19px;">
            <h2>Get Started with Auxo Ads Newsletter</h2>
            <span class="section-title-line"></span>
         </div>
         <div class="row">
            <div class="col-md-10 mx-auto wow animated fadeInUp" data-wow-delay="0.12s">
               <div class="sign-up-formdelete">
                  
                  <form [formGroup]="newsletterform" (ngSubmit)="nwsltrSubmit()" style="margin-top: -5px;">
                     <div fxLayout="row wrap" class="align-items-center">
                        <div fxFlex="100" fxFlex.gt-md="40" class="p-10">
                           <mat-form-field appearance="outline" class="p-r-0">
                              <mat-label>Your Name*</mat-label>
                              <input matInput [formControl]="newsletterform.controls['nwsltrname']"
                                 placeholder="Your Name">
                           </mat-form-field>
                           <small *ngIf="newsletterform.controls['nwsltrname'].errors?.whitespace && newsletterform.controls['nwsltrname'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Please enter valid name</small>
                           <small *ngIf="newsletterform.controls['nwsltrname'].errors?.minlength"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth"> Name must be 3 characters</small>
                           <small *ngIf="newsletterform.controls['nwsltrname'].errors?.pattern"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Name must be characters</small>
                        </div>
                        <div fxFlex="100" fxFlex.gt-md="40" class="p-10">
                           <mat-form-field appearance="outline" class="p-r-0">
                              <mat-label>Your Email</mat-label>
                              <input type="email" matInput required [formControl]="newsletterform.controls['nwsltremail']"
                                 placeholder="Your Email">
                           </mat-form-field>
                           <small *ngIf="newsletterform.controls['nwsltremail'].errors && newsletterform.controls['nwsltremail'].touched"  class="text-danger support-text logintrp-css spacing-adjust m-b-20 customWidth">Please enter valid email</small>
                        </div>
                        <div fxFlex="100" fxFlex.gt-md="20" class="p-10" style="margin-top: -30px;">
                           <button type="submit" id="reg_submit_news" name=""
                              class="btn btn-primary btn-lg btn-block m-t-10 mbilesub" style="padding: 0px;">Subscribe
                              Now</button>
                        </div>
                     </div>
                  </form>
               </div>
               <p class="mt-1 text-center">We believe Privacy is important & don't sell data to any third-party</p>
            </div>
         </div>
      </div>
   </section>

   <!-- Footer start -->
   <footer class="py-5 text-center wow animated zoomIn">
      <div class="container">
         <div class="row">
            <div class="col-md-3  col-sm-6 col-xs-6">
            <ul class="list-inline links links1"> 
             <p class="footertitle">OUR OFFICES</p>
             <!-- <p class="footertitle" style="font-size: 15px!important;"><u>Singapore Office</u></p> -->
           <u><li style="font-size: 15px!important; font-weight: 500;">Singapore Office</li></u> 
             <li>1 North Bridge Road, #07-10 </li>
             <li>High Street Center</li>
             <li>Singapore - 179094</li>
             </ul>

             <ul class="list-inline links links1"> 
               <br>
            <!-- <p class="footertitle" style="font-size: 15px!important;"><u>India Office</u></p> -->
               <u><li style="font-size: 15px!important; font-weight: 500;">India Office</li></u> 
               <li>Cyber House B-35 Sec-32  </li>
               <li>Gurgaon (NCR Delhi)</li>
               <li>India - 122001</li>
               </ul>
            </div>


         <div class="col-md-3 col-sm-6 col-xs-6">
            <ul class="list-inline links"><p class="footertitle">PLATFORM</p>
               <li><a href="#gam">Ads Management</a></li>
               <li><a href="#d-ad">Programmatic Demand</a></li>
               <li><a href="#report">Unified Reporting</a></li>
               <li><a href="#ed">Editorial Dashboard</a></li>
               <li><a href="#da">Deep Analytics</a></li>
               <li><a href="#afm">Auxo Ads Log In & Payments</a></li>
            </ul>
         </div>

         <div class="col-md-3 col-sm-6 col-xs-6">
				<ul class="list-inline links"><p class="footertitle">SERVICES</p>
            <li><a href="#cdp">Customer Data Platform (CDP)</a></li>
            <li><a href="#ao">Ad Operation Management</a></li>
            <li><a href="#seo">Traffic Optimization</a></li>
            <li><a href="#cs">Content Strategy</a></li>
				</ul>
			</div>

         <div class="col-md-3 col-sm-6 col-xs-6 logos">
				<ul class="list-inline links"><p class="footertitle">CONTACT</p>
					<li style="font-size:14px;"><strong>Email : </strong> <a href="mailto:enquiry@auxoads.com">enquiry@auxoads.com</a></li>
             <!--   <li>
                  <img src="assets/registerlp/img/new/safe-logo.png" class="marginfooter" style="margin-left: -10px!important;" width="170" height="auto">
               </li> -->


               <li>
                  <a href="#home"><img src="assets/registerlp/img/new/safe-logo.png" class="marginfooter" style="margin-left: -10px!important;" width="170" height="auto"></a>
               </li>
				</ul>

           <!-- <span style="float:left;">
				<a href="https://www.facebook.com/CyberMedia-India-Limited-127319944012858/" target="_blank"><i style="font-size: 25px;" class="fa fa-facebook-official" aria-hidden="true"></i></a>
	
				<a href="https://in.linkedin.com/company/cybermedia" target="_blank"><i style="font-size: 25px; margin-left: 5px;" class="fa fa-linkedin-square" aria-hidden="true"></i></a>
				</span>
            <br>

          <ul class="list-inline links">
             <li>
            <img src="assets/registerlp/img/safe-logo.png" style="margin-left: -60px!important; margin-top: 15px!important;" width="170" height="auto">
             </li>
          </ul> -->
		 </div>

      </div>
   </div>

   <div class="container" style="border-top:1px solid #f1f1f1; margin-top:30px;">
      <p style="position: relative; top: 17px;" class="mt-0 mb-0 pt-2">&copy; Copyright 2023  <a class="text-primary"  target="_blank" href="/">Auxo Ads</a>. All Rights Reserved.</p>
   </div>
</footer>
<!--loader-->
<div class="bg-opacity" *ngIf="isSpinnerVisiblenewsletter">
   <div class="parent parent-landingpage">
      <div class="spin1"></div>
   </div>
</div>
<!--loaderend-->
<!--loader-->
<div class="bg-opacity" *ngIf="isSpinnerVisible">
   <div class="parent parent-landingpage">
   <div class="spin1"></div>
   </div>
</div>
<!--loaderend-->
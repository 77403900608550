import { Component, Inject, OnInit } from '@angular/core';
import { NotificationHubService } from './notification-hub.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DialogDynamicComponent } from '../dialog-dynamic/dialog-dynamic.component';

declare var $: any;
const MaxFileSize =1024000;
// Popup start
@Component({
    selector: 'app-sms-verification',
    templateUrl: 'sms-verification.component.html'
})

export class SmsVerificationComponent implements OnInit {
  
    isSpinnerVisible: boolean; 
    login_uniq_id: string;
    private pub_uniq_id: string;
    public myForm: FormGroup;
    isOtp=false;
    isMobileVerified = false;
    isMobileUpdated = false;
    hasFileValidatinError: boolean;  
    message: string;
    successMessage:string='';
    contact: string;
    constructor(
        public dialogRef: MatDialogRef<SmsVerificationComponent>,
        @Inject(MAT_DIALOG_DATA) public model: any,private formBuilder: FormBuilder, private route:ActivatedRoute,public dialog: MatDialog,private _notificationHubService:NotificationHubService
    ) { 
      this.myForm = this.formBuilder.group({
        mob: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        otp:[{value:'',disabled: true},[Validators.required]]
      }) ;
      this.login_uniq_id=localStorage.getItem('uniq_id');
      this.contact=model.mobile;
    }
    ngOnInit(): void {
      this.isMobileVerified=false;
      this.isMobileUpdated=false;
    }
    onNoClick(): void {
        this.dialogRef.close(false);
    }
    get m(){
      return this.myForm.controls;
    }
    onSubmit(){  
      if(this.myForm.invalid){
        return;
      }   
      if(this.isOtp){
        this.verifyOtp(this.myForm.get('mob').value,this.myForm.get('otp').value);
      } 
      else{
       this.sendOtp(this.myForm.get('mob').value);
      }
    }  
    sendOtp(mobileno:string){
      this.isSpinnerVisible=true;
      
      this.successMessage='';
      this._notificationHubService
      .sendOtp(this.login_uniq_id,mobileno,'')
      .subscribe((response)=>{
        this.isSpinnerVisible=false;
            if(response && response['status_code'] == '200'){
              this.myForm.get('mob').disable();
              this.myForm.get('otp').enable();
              this.myForm.get('otp').setValidators([Validators.required]);
              this.isOtp=true;
              this.dialog.open(DialogDynamicComponent,
                {
                  width: '450px',
                  data: { message: 'OTP has been sent successfully.' }
                });
            }
            else {
              this.message = 'Would you like to update your profile with this mobile number?';
              let d = this.dialog.open(DialogDynamicComponent,
                      {
                        width: '450px',
                        data: { message: this.message, okText:'Yes', cancelText:'No'  }
                      });
                      
              d.afterClosed().subscribe(result=>{
                if(result == 'OK'){
                  this.isSpinnerVisible=true;
                  this._notificationHubService
                  .sendOtp(this.login_uniq_id,mobileno,'update')
                  .subscribe((response2)=>{
                    this.isSpinnerVisible=false;
                    if(response2 && response2['status_code'] == '200'){
                       this.isMobileUpdated=true;
                      this.myForm.get('mob').disable();
                      this.myForm.get('otp').enable();
                      this.myForm.get('otp').setValidators([Validators.required]);
                      this.isOtp=true;
                      this.dialog.open(DialogDynamicComponent,
                      {
                        width: '450px',
                        data: { message: 'OTP has been sent successfully.' }
                      });
                    }
                  });
                }
              });
            }
        });
    }
    resendOtp(){
      if(!this.isOtp){
        return;
      }   
      this.sendOtp(this.myForm.get('mob').value);
    }
    verifyOtp(mobileno:string,otp:string){
    this.isSpinnerVisible = true;
      this._notificationHubService
      .verifyOtp(this.login_uniq_id,mobileno,otp)
      .subscribe((response)=>{
        this.isSpinnerVisible=false;
            if(response && response['message'] == 'Valid Otp'){
              this.isMobileVerified=true;
              this.dialogRef.close(false);
              this.dialog.open(DialogDynamicComponent,
                {
                  width: '450px',
                  panelClass:"break-spaces",
                  data: { message: 'Your mobile number has been \n verified.' }
                });
            }
            else
            {      
              this.dialog.open(DialogDynamicComponent,
                {
                  width: '450px',
                  data: { message: 'Invalid OTP entered.' }
                });
            }
        });
    }
     
}
// popup end

